const parse = (value) =>
  parseFloat(
    value
      .toString()
      .replace('.', '')
      .replace(',', '.')
      .replace('R$', '')
      .trim(),
  );

export const moneyToFloat = (value) => {
  if (value == null) return 0;

  if (typeof value === 'number') return value;

  return parse(value);
};
