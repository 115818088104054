import { useContext, useState } from 'react';
import { Input } from 'antd';
import AutoCompleteSearch from '../components/autoCompleteSearch/autoCompleteSearch';
import BillingsTable from '../components/billingsTable/billingsTable';
import DateRangePicker from '../components/dateRangePicker/dateRangePicker';
import { StatusSelect } from '../components/statusSelect/statusSelect';
import { GET_SHIPPER_NAMES } from '../graphql';
import './css/root.css';
import BranchSelect from '../components/branchSelect/branchSelect';
import { SelectedShipperContext } from '../context/selectedShipperContext';

export default function Billings() {
  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedShipper, setSelectedShipper] = useState({
    id: '',
    name: '',
    document:''
  });
  const [financialId, setFinancialId] = useState(undefined);
  const { selectedBranch, setSelectedBranch } = useContext(SelectedShipperContext);

  const onStatusSelected = (value) => {
    setSelectedStatus(value);
  };

  const onShipperSelected = (value) => {
    setSelectedShipper(value);
  };

  const onDatePicked = (date) => {
    setSelectedRange(date);
  };

  const selectOptions = ['Cancelada', 'Em Aberto', 'Liquidado', 'Vencido'];

  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h6>Tabela de Faturas</h6>
        <div className="filters-wrapper">

          <Input.Search
            placeholder='Fatura' 
            onSearch={value => setFinancialId(value)}
            onBlur={(e)=> setFinancialId(e.target.value)}
            style={{ width: 'max(30%, 300px)' }}
          />
          <StatusSelect
            onStatusSelected={onStatusSelected}
            options={selectOptions}
          />
          <AutoCompleteSearch
            onSelected={onShipperSelected}
            query={GET_SHIPPER_NAMES}
          />
          {(selectedShipper.branches?.length > 0) &&(
            <BranchSelect selectedShipper={selectedShipper} onSelected={(value) => setSelectedBranch(value)}/>
          )}
          <DateRangePicker onDatePicked={onDatePicked} />

        </div>

        <BillingsTable
          shipperId={selectedShipper.id}
          shipperName={selectedShipper.name}
          shipperDocument={selectedShipper.document}
          billingDocument={selectedBranch.document}
          status={selectedStatus}
          dateRange={selectedRange}
          financialId={financialId}
        />
      </div>
    </div>
  );
}
