import Cookies from 'universal-cookie';

const checkValidation = () => {
  const cookies = new Cookies();
  const result = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);

  if (!result) {
    window.localStorage.clear();

    cookies.remove(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    if(document.location.pathname !== '/')
      document.location.href = '/';
  }
};

export { checkValidation };
