import { Select } from 'antd';

const { Option } = Select;

export function StatusSelect({ onStatusSelected, options, defaultOption }) {
  const handleChange = (value) => {
    onStatusSelected(value);
  }

  return (
    <Select
      style={{ width: 250, minWidth: '0' }}
      onChange={handleChange}
      allowClear
      placeholder={'Status financeiro'}
      defaultValue={defaultOption}
    >
      {options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
}
