import '../components/css/freightsTable.css';
import ShipperAggregationTable from '../components/shipperAggregationTable/shipperAggregationTable';
import './css/root.css';

function ShipperAggregation() {
  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h6>Embarcadores</h6>

        <ShipperAggregationTable />
      </div>
    </div>
  );
}

export default ShipperAggregation;
