import { useContext, useState } from 'react';
import DateRangePicker from '../components/dateRangePicker/dateRangePicker';
import AutoCompleteSearch from '../components/autoCompleteSearch/autoCompleteSearch';
import { GET_SHIPPER_NAMES } from '../graphql';
import { StatusSelect } from '../components/statusSelect/statusSelect';
import '../components/css/freightsTable.css';
import BoletosTable from '../components/boletosTable/boletosTable';
import './css/root.css';
import BranchSelect from '../components/branchSelect/branchSelect';
import { SelectedShipperContext } from '../context/selectedShipperContext';

function Boletos() {
  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedShipper, setSelectedShipper] = useState({
    id: '',
    name: '',
  });
  const { selectedBranch, setSelectedBranch } = useContext(SelectedShipperContext);

  const convertKnownStatusToEnglish = (status) => {
    switch (status) {
      case 'Criado':
        return 'created';
      case 'Registrado':
        return 'registered';
      case 'Falhou':
        return 'failed';
      case 'Cancelado':
        return 'canceled';
      case 'Atrasado':
        return 'overdue';
      case 'Pago':
        return 'paid';
      case 'Substituido':
        return 'substituted';
      case 'Deletado':
        return 'deleted';
      default:
        return status;
    }
  }

  const onStatusSelected = (value) => {
    setSelectedStatus(
      convertKnownStatusToEnglish(value)
    );
  };

  const onShipperSelected = (value) => {
    setSelectedShipper(value);
  };

  const onDatePicked = (date) => {
    setSelectedRange(date);
  };

  const selectOptions = [
    'Criado',
    'Registrado',
    'Falhou',
    'Cancelado',
    'Atrasado',
    'Pago',
    'Substituido',
    'Deletado',
  ];

  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h6>Boletos</h6>
        <div className="filters-wrapper">
          <DateRangePicker onDatePicked={onDatePicked} />
          <StatusSelect
            onStatusSelected={onStatusSelected}
            options={selectOptions}
          />
          <AutoCompleteSearch
            onSelected={onShipperSelected}
            query={GET_SHIPPER_NAMES}
          />
          {(selectedShipper.branches?.length > 0) &&(
            <BranchSelect selectedShipper={selectedShipper} onSelected={(value) => setSelectedBranch(value)}/>
          )}
        </div>
        <BoletosTable
          dateRange={selectedRange}
          shipperName={selectedShipper.name}
          shipperDocument={selectedShipper.document}
          billingDocument={selectedBranch.document}
          status={selectedStatus}
        />
      </div>
    </div>
  );
}

export default Boletos;
