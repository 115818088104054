import { DownloadOutlined, SyncOutlined, CaretRightOutlined, EnterOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Input,
  Table,
  Card,
  Row,
  Col,
  Modal,
  Form,
  DatePicker,
  Select,
  Typography,
  Divider,
  Spin,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { convertToReal } from '../../common/formatCurrency';
import { SelectedShipperContext } from '../../context/selectedShipperContext';
import {
  BILL_SHIPPER,
  GET_SHIPPER_AGGREGATION,
  GET_DISCOUNTS_BY_SHIPPER,
} from '../../graphql';
import saveCsv from '../../services/csv-exporter';
import { checkValidation } from '../../services/logout-service';
import '../css/freightsTable.css';
import { CustomSpinner } from '../customSpinner/customSpinner';
import Error from '../error/error';
import { formatDocument } from '../../common/formatDocument';
const { Search } = Input;

const ShipperAggregationTable = () => {
  const { selectedShipper, setSelectedShipper } = useContext(
    SelectedShipperContext,
  );

  const [modalShipper, setModalShipper] = useState(undefined);
  const [modalForm] = Form.useForm();
  const [selectedDiscountValues, setSelectedDiscountValues] = useState(0);
  const {
    loading: loadingDiscounts,
    data: discountsData,
    refetch: refetchDiscounts,
  } = useQuery(GET_DISCOUNTS_BY_SHIPPER, {
    variables: {
      shipperIds: [modalShipper?.id],
    },
    skip: !modalShipper?.id,
  });

  useEffect(() => {
    if (modalShipper?.id) refetchDiscounts();
  }, [modalShipper]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [loadingBtnId, setLoadingBtnId] = useState('');
  const [pageSize, setPageSize] = useState(100);
  const [inputShipperDocument, setInputShipperDocument] = useState('');
  const [shipperDocument, setShipperDocument] = useState('');

  const { loading, data, error, refetch } = useQuery(GET_SHIPPER_AGGREGATION, {
    variables: {
      selectedPage,
      pageSize,
      document: shipperDocument?.length ? shipperDocument : undefined,
    },
    errorPolicy:'ignore' 
  });

  useEffect(() => {
    refetch();
  }, [pageSize, selectedPage]);

  const [billShipper, mutation] = useMutation(BILL_SHIPPER);

  const history = useHistory();

  const handleFreightsClick = (record) => {
    setSelectedShipper(record);
    history.push(`/`);
  };

  const handleBillButton = (id) => {
    billShipper({
      variables: {
        shipper: id,
      },
    }).catch((err) => console.log(err));
  };

  const handleCpfCnpjChange = (event) => {
    // Get only the numbers from the data input
    let data = event.target.value.replace(/\D/g, '');
    // Checking data length to define if it is cpf or cnpj
    if (data.length > 11) {
      // It's cnpj
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
        5,
        3,
      )}/`;
      if (data.length > 12)
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      else cnpj += data.substr(8);
      // Pass formatting for the data
      data = cnpj;
    } else {
      // It's cpf
      let cpf = '';
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
      }
      // Pass formatting for the data
      data = cpf;
    }
    // Update state
    setInputShipperDocument(data);
  };

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomSpinner fontSize={50} />
      </div>
    );

  if (error) return <Error error={error} />;

  const values = data.getShipperAggregations.shippers;
  const meta = data.getShipperAggregations.meta;
  const dataRows = [];

  const sumValues = (array, key) => array?.reduce((acc, item) => acc + item[key], 0) ?? 0;

  values.map((item) => {
    dataRows.push({
      ...item,
      key: item.id,
      id: item.id,
      shipper: item.name,
      document: item.document,
      createdAt: new Date(item.created_at) ?? null,
      nextFinancialClosureDay:
        new Date(item.next_financial_closure_day) ?? null,
      effectivation: item.effectivationValue + sumValues(item.branches, 'effectivationValue') ?? null,
      effectivationServiceValue: item.effectivationServiceValue + sumValues(item.branches, 'effectivationServiceValue') ?? null,
      effectivationDebitValue: item.effectivationDebitValue + sumValues(item.branches, 'effectivationDebitValue')  ?? null,
      prediction: item.predictionValue ?? null,
      projection: item.projectionValue ?? null,
      freights: {
        id: item.id,
        name: item.name,
      },
      bill: item.id,
      discountValue: item.discountValue,
      children: item?.branches?.length ? 
        [
          {
            key: item.id + 'main',
            shipper: 'Matriz', 
            document: item.document, 
            effectivation: item.effectivationValue ?? null,
            effectivationServiceValue: item.effectivationServiceValue ?? null,
            effectivationDebitValue: item.effectivationDebitValue ?? null,
          },
          ...item.branches.map((branch)=>
          ({
            key: item.id + branch.document,
            shipper: branch.name, 
            document: branch.document, 
            effectivation: branch.effectivationValue ?? null,
            effectivationServiceValue: branch.effectivationServiceValue ?? null,
            effectivationDebitValue: branch.effectivationDebitValue ?? null,
          }))
        ] 
        : null
    });
  });

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 48,
      render:(_,record)=>
        <span>
          <span style={{ whiteSpace: 'nowrap', display:record.id || record.shipper === 'Matriz' ? 'none': undefined }}>
            Filial
          </span>
          <EnterOutlined style={{transform: 'scaleX(-1)', display:record.id ? 'none': undefined , marginLeft: 16}} />
        </span>,
    },
    {
      title: 'Embarcador',
      dataIndex: 'shipper',
      key: 'shipper',
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
      render: (document) => formatDocument(document),
    },
    {
      title: 'Pronto para Faturar',
      dataIndex: 'effectivation',
      key: 'effectivation',
      render: (text, record) => 
        <>{text ? convertToReal(text) : convertToReal(0)}</>
      ,
      sorter: (a, b) => {
        return !a.effectivation
          ? -1
          : !b.effectivation
          ? 1
          : a.effectivation - b.effectivation;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Valor Serviço',
      dataIndex: 'effectivationServiceValue',
      key: 'effectivationServiceValue',
      render: (text, record) => 
        <>{text ? convertToReal(text) : convertToReal(0)}</>
      ,
      sorter: (a, b) => {
        return !a.effectivationServiceValue
          ? -1
          : !b.effectivationServiceValue
          ? 1
          : a.effectivationServiceValue - b.effectivationServiceValue;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Valor Transportador',
      dataIndex: 'effectivationDebitValue',
      key: 'effectivationDebitValue',
      render: (text, record) => 
        <>{text ? convertToReal(text) : convertToReal(0)}</>
      ,
      sorter: (a, b) => {
        return !a.effectivationDebitValue
          ? -1
          : !b.effectivationDebitValue
          ? 1
          : a.effectivationDebitValue - b.effectivationDebitValue;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Pedidos Criados',
      dataIndex: 'projection',
      key: 'projection',
      render: (text, record) => record.id?(
        <>{text ? convertToReal(text) : convertToReal(0)}</>
      ):'-',
      sorter: (a, b) => {
        return !a.projection
          ? -1
          : !b.projection
          ? 1
          : a.projection - b.projection;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Aguardando Auditoria',
      dataIndex: 'prediction',
      key: 'prediction',
      render: (text, record) => record.id?(
        <>{text ? convertToReal(text) : convertToReal(0)}</>
      ):'-',
      sorter: (a, b) => {
        return !a.prediction
          ? -1
          : !b.prediction
          ? 1
          : a.prediction - b.prediction;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => record.id?(
        <>{text ? text.toLocaleDateString('pt-br') : null}</>
      ):'-',
      sorter: (a, b) => {
        return !a.createdAt
          ? -1
          : !b.createdAt
          ? 1
          : a.createdAt.getTime() - b.createdAt.getTime();
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Desconto disponível',
      dataIndex: 'discountValue',
      key: 'discountValue',
      render: (text, record) => record.id?(
        <>{text ? convertToReal(text) : convertToReal(0)}</>
      ):'-',
    },
    // {
    //   title: 'Fechamento Esperado',
    //   dataIndex: 'nextFinancialClosureDay',
    //   key: 'nextFinancialClosureDay',
    //   render: (text, _record) => (<>{text ? text.toLocaleDateString('pt-br') : null}</>),
    //   sorter: (a, b) => {
    //     return (!a.nextFinancialClosureDay) ? -1 : (!b.nextFinancialClosureDay) ? 1 : a.nextFinancialClosureDay.getTime() - b.nextFinancialClosureDay.getTime();
    //   },
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: 'Fretes',
      dataIndex: 'freights',
      key: 'freights',
      render: (record, shipper) => shipper.id?(
        <Button type="default" onClick={() => handleFreightsClick(record)}>
          Fretes
        </Button>
      ):null,
      align: 'center',
    },
    {
      title: 'Faturar',
      dataIndex: 'bill',
      key: 'bill',
      render: (record, shipper) => shipper.id?(
        <Button
          type="primary"
          onClick={() => {
            setModalShipper(shipper);
          }}
        >
          {mutation.loading && record == loadingBtnId ? (
            <span>
              <CustomSpinner fontSize={12} marginTop="0px" />
            </span>
          ) : (
            <>Faturar</>
          )}
        </Button>
      ):null,
      align: 'center',
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {mutation.error ? (
        <Alert
          description={mutation.error.message}
          type="error"
          closable
          showIcon
        />
      ) : null}

      {mutation.data ? (
        <Alert
          description="Fretes faturados"
          type="success"
          closable
          showIcon
        />
      ) : null}

      <div className="button-group">
        <Button
          icon={<DownloadOutlined />}
          className="download"
          onClick={() => {
            saveCsv(
              'shippers',
              // `Embarcador;Pronto para Faturar;Pedidos Criados;Aguardando Auditoria;Data de Criação;Fechamento Esperado`,
              `Embarcador;Documento;Pronto para Faturar;Pedidos Criados;Aguardando Auditoria;Data de Criação;`,
              (() => {
                const csvData = [];

                values.map((item) => {
                  let totalValue = 0;
                  if (item.nfses) {
                    for (let nfse of item.nfses) {
                      totalValue += nfse.value;
                    }
                  }

                  const createdAt = new Date(item.createdAt);
                  const due = new Date(item.createdAt);
                  due.setDate(createdAt.getDate() + 2);

                  csvData.push([
                    item.name ?? '',
                    item.effectivationValue ?? '',
                    item.projectionValue ?? '',
                    item.predictionValue ?? '',
                    new Date(item.created_at).toLocaleDateString('pt-br') ?? '',
                    new Date(
                      item.next_financial_closure_day,
                    ).toLocaleDateString('pt-br') ?? '',
                  ]);
                });

                return csvData;
              })(),
            );
          }}
        >
          Exportar CSV
        </Button>
        <Search
          value={inputShipperDocument}
          placeholder="Digite o CNPJ/CPF"
          style={{ width: '30%' }}
          onPressEnter={(e) => setShipperDocument(inputShipperDocument)}
          onSearch={(e) => setShipperDocument(inputShipperDocument)}
          onChange={handleCpfCnpjChange}
        />
        <Button
          icon={<SyncOutlined />}
          className="atualizar"
          onClick={() => {
            checkValidation();
            refetch();
          }}
        >
          Atualizar
        </Button>
      </div>

      <Table
        className={'FreightTable'}
        size="middle"
        columns={columns}
        dataSource={dataRows}
        scroll={{ x: 1000 }}
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setSelectedPage(page);
            setPageSize(pageSize);
          },
          total: meta.totalItems,
          current: selectedPage,
        }}
        expandable={{
          expandIcon:(props)=>{
            return (
                <CaretRightOutlined 
                  onClick={(e)=>{
                    if(!props.record?.children?.length) return;
      
                    else return  props.onExpand(props.record, e);
                  }}
                  style={{
                    padding:2,
                    verticalAlign:'text-bottom',
                    transform: props.expanded ? 'rotate(90deg)': undefined,
                    marginRight:4,
                    display:!props.record?.children?.length? 'none': undefined,
                    fontSize: 10
                  }} 
                  type="caret-right" 
                />
            );
          }
        }}
      />

      <Modal
        title="Faturar"
        visible={Boolean(modalShipper)}
        onCancel={() => {
          setModalShipper(undefined)
          modalForm.resetFields(['discounts'])
          setSelectedDiscountValues(0)
        }}
        onOk={() => {
          modalForm.validateFields().then(() => {
            const values = modalForm.getFieldsValue();
            billShipper({
              variables: {
                shipperId: modalShipper.id,
                fiscalEmission: values.emission_date.format?.('YYYY-MM-DD'),
                initialDateBill: values.closing_date[0].format?.('YYYY-MM-DD'),
                finalDateBill: values.closing_date[1].format?.('YYYY-MM-DD'),
                discounts: values.discounts,
              },
            }).catch(() => {
              refetch()
            })
            .then(() => {
              refetch()
              setModalShipper(undefined)
              modalForm.resetFields(['discounts'])
              setSelectedDiscountValues(0)
            });
          });
        }}
        okButtonProps={{
          disabled:
            (selectedDiscountValues ?? 0) > (modalShipper?.effectivation ?? 0),
        }}
      >
        <Spin spinning={mutation.loading}>
          <Form form={modalForm}>
            <Form.Item
              label="Embarcador"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input
                disabled
                value={`${modalShipper?.shipper} (${modalShipper?.document})`}
              />
            </Form.Item>
            <Form.Item
              name="emission_date"
              label="Data da emissão fiscal"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <DatePicker
                placeholder="Data da emissão fiscal"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="discounts"
              label="Selecionar descontos"
              rules={[{ required: false }]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Selecionar descontos"
                disabled={loadingDiscounts}
                options={
                  discountsData?.getByShipperId?.map?.(
                    ({ id, freight, value }) => ({
                      label: `Pedido #${freight?.number} (R$ ${value?.toFixed?.(2)})`,
                      value: id,
                      discount: value,
                    }),
                  ) ?? []
                }
                onChange={(_, options) =>
                  setSelectedDiscountValues(
                    options
                      ?.map?.(({ discount }) => discount ?? 0)
                      ?.reduce?.((a, b) => a + b, 0) ?? 0,
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="closing_date"
              label="Fechamento"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <DatePicker.RangePicker style={{ width: '100%' }} />
            </Form.Item>
          </Form>
        </Spin>
        <Row>
          <Col span={24}>
            <Typography.Title level={5}>Resumo da fatura</Typography.Title>
          </Col>
          <Col span={24}>
            <Divider orientation="center" />
          </Col>
          <Col span={24}>
            <Row
              justify="space-between"
              style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
            >
              <Typography.Title level={5}>Valor cheio</Typography.Title>
              <Typography.Text>{`R$ ${(
                modalShipper?.effectivation ?? 0
              )?.toFixed(2)}`}</Typography.Text>
            </Row>
          </Col>
          <Col span={24}>
            <Row
              justify="space-between"
              style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
            >
              <Typography.Title level={5}>Desconto aplicado</Typography.Title>
              <Typography.Text>{`R$ ${
                selectedDiscountValues?.toFixed(2) ?? 0
              }`}</Typography.Text>
            </Row>
          </Col>
          <Col span={24}>
            <Row
              justify="space-between"
              style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
            >
              <Typography.Title level={5}>Valor do serviço</Typography.Title>
              <Typography.Text>{`R$ ${(
                modalShipper?.effectivationServiceValue ?? 0
              )?.toFixed(2)}`}</Typography.Text>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              backgroundColor: '#4787BF',
              borderRadius: 6,
              padding: '0.5rem 0.5rem 0 0.5rem',
            }}
          >
            <Row justify="space-between">
              <Typography.Title style={{ color: 'white' }} level={5}>
                Total à pagar
              </Typography.Title>
              <Typography.Text style={{ color: 'white' }}>{`R$ ${(
                (modalShipper?.effectivation ?? 0) -
                (selectedDiscountValues?.toFixed(2) ?? 0)
              ).toFixed(2)}`}</Typography.Text>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ShipperAggregationTable;
