import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'universal-cookie';

const link = createHttpLink({
  uri: process.env.REACT_APP_MS_FINANCIAL_HOST,
  credentials: process.env.REACT_APP_REQUEST_CREDENTIALS,
});

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);
  
  return {
    headers: {
      ...headers,
      'Authorization': token ? `Bearer ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

export const baseBucketUrl = `https://${process.env.REACT_APP_SMART_S3_BUCKET}.s3.amazonaws.com/${process.env.REACT_APP_STAGE}`;
