import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import {
  Button,
  Table,
  Statistic,
  Card,
  Row,
  Col,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  Typography,
  Divider,
  Spin,
} from 'antd';
import '../css/freightsTable.css';
import { SyncOutlined, DownloadOutlined } from '@ant-design/icons';
import { convertToReal } from '../../common/formatCurrency';
import { CustomSpinner } from '../customSpinner/customSpinner';
import { useQuery } from '@apollo/client';
import {
  FREIGHT_BY_FILTER_PARAMS,
  FREIGHT_BY_FILTER_PARAMS_ALL,
  GET_SHIPPER_NAMES,
  DISCOUNTS_BY_FILTER_PARAMS,
  GET_DISCOUNTS_BY_SHIPPER,
} from '../../graphql';
import Error from '../error/error';
import saveCsv from '../../services/csv-exporter';
import { checkValidation } from '../../services/logout-service';
import { formatDocument } from '../../common/formatDocument';

const FreightsTable = forwardRef(
  (
    { freightsQueryParams, onFreightSelect, billFreightsById, loadingBill },
    ref,
  ) => {
    const [loadingOk, setLoadingOk] = useState(false);
    const [selectedFreights, setSelectedFreights] = useState([]);
    const [selectedFreightsItems, setSelectedFreightsItems] = useState([]);

    const paginationFixedSize = 5000; // not so temporary change to acomodate backend
    const [selectedPage, setSelectedPage] = useState(1);
    const [pageSize, setPageSize] = useState(paginationFixedSize);
    useEffect(() => {
      onFreightSelect(selectedFreights);
    }, [selectedFreights]);

    let {
      shipperName,
      shipperId,
      dateRange,
      deliveryStatusName,
      shipperDocument,
      billingDocument,
    } = freightsQueryParams;

    let after, before;

    shipperName === '' || null ? (shipperName = null) : null;

    deliveryStatusName === '' || null ? (deliveryStatusName = null) : null;

    if (dateRange.length <= 1) {
      after = null;
      before = null;
    } else if (dateRange.length > 1) {
      after = new Date(dateRange[0]);
      before = new Date(dateRange[1]);
    }

    const { loading, data, error, refetch } = useQuery(
      FREIGHT_BY_FILTER_PARAMS,
      {
        variables: {
          deliveryStatusName,
          after,
          before,
          selectedPage: selectedPage - 1,
          pageSize,
          shipperDocument,
          billingDocument,
        },
        skip: !(shipperDocument || billingDocument),
        fetchPolicy: !(shipperDocument || billingDocument) ? 'cache-only' : 'network-only',
      },
    );

    useEffect(() => {
      if(shipperDocument)
        refetch();
    }, [pageSize, selectedPage, shipperDocument, billingDocument]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalForm] = Form.useForm();

    const {
      loading: loadingDiscounts,
      data: discountsData,
      refetch: refetchDiscounts,
    } = useQuery(GET_DISCOUNTS_BY_SHIPPER, {
      variables: {
        shipperIds: [shipperId]
      },
      skip: !shipperId,
    });
    const [selectedDiscountValues, setSelectedDiscountValues] = useState(0);
    const formBillValues = useMemo(
      () =>
        selectedFreightsItems.reduce(
          (a, b) => {
            return {
              totalValue: a.totalValue + b.totalValue,
              serviceValue: a.serviceValue + b.serviceValue,
            };
          },
          { totalValue: 0, serviceValue: 0 },
        ),
      [selectedFreightsItems],
    );

    useImperativeHandle(ref, () => ({
      clearSelection: () => {
        setSelectedFreights([]);
      },
      refetchData: () => {
        refetch();
      },
      openModal: () => {
        refetchDiscounts();
        setIsModalOpen(true);
      },
    }));

    if (loading)
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomSpinner fontSize={50} />
        </div>
      );

    if (error) return <Error error={error} />;

    const values = data?.freightByFilterParams?.freights ?? [];
    const meta = data?.freightByFilterParams?.meta;
    const dataRows = [];

    values.map((item) => {
      dataRows.push({
        ...item,
        key: item.id,
        id: item.id,
        shipper: item.shippername,
        document: item.shipperdocument,
        number: item.number,
        createdAt: new Date(item.created_at) ?? null,
        totalValue: item.estimate_value ?? null,
        shippingCompanyValue: item.estimate_pure_value ?? null,
        serviceValue: item.estimate_revenue ?? null,
        status: item.status,
        financialId: item.financialId ?? 'Não Faturado',
        shippingCompany: item.shipping_name ?? null,
        destinyName: item.destiny_name,
        nfses: item.nfe_serie_number,
        boleto: item.boleto,
        nfse: item.nfse,
      });
    });

    const checkRowSelection = (record) => {
      return !(
        record.status.toLowerCase() == 'aguardando faturamento' ||
        (record.financialId !== 'Não Faturado' &&
          (!record.nfse || !record.boleto))
      );
    };

    const rowSelection = {
      selectedRowKeys: selectedFreights,
      onChange: (selectedRowKeys, selectedRowItems) => {
        setSelectedFreights(selectedRowKeys);
        setSelectedFreightsItems(selectedRowItems);
      },
      getCheckboxProps: (record) => ({
        disabled: checkRowSelection(record),
        name: record.name,
      }),
    };

    const columns = [
      {
        title: 'Embarcador',
        dataIndex: 'shipper',
        key: 'shipper',
      },
      {
        title: 'Documento matriz',
        dataIndex: 'document',
        key: 'document',
      },
      {
        title: 'Documento faturado',
        dataIndex: 'billing_document',
        key: 'billing_document',
        render: (_, record) => record.billing_document ? formatDocument(record.billing_document): record.document,
      },
      {
        title: 'Transportador',
        dataIndex: 'shippingCompany',
        key: 'shippingCompany',
      },
      {
        title: 'Destinatário',
        dataIndex: 'destinyName',
        key: 'destinyName',
      },
      {
        title: 'Número',
        dataIndex: 'number',
        key: 'number',
        sorter: (a, b) => a.number - b.number,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Data de Criação',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text, _record) => {
          return <>{text.toLocaleDateString('pt-br')}</>;
        },
        sorter: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Valor Total',
        dataIndex: 'totalValue',
        key: 'totalValue',
        render: (text, _record) => {
          return <>{convertToReal(text)}</>;
        },
        sorter: (a, b) => a.totalValue - b.totalValue,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Valor Serviço',
        dataIndex: 'serviceValue',
        key: 'serviceValue',
        render: (text, _record) => {
          return <>{convertToReal(text)}</>;
        },
        sorter: (a, b) => a.serviceValue - b.serviceValue,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Valor Transportador',
        dataIndex: 'shippingCompanyValue',
        key: 'shippingCompanyValue',
        render: (text, _record) => {
          return <>{convertToReal(text)}</>;
        },
        sorter: (a, b) => a.shippingCompanyValue - b.shippingCompanyValue,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Número da Fatura',
        dataIndex: 'financialId',
        key: 'financialId',
        sorter: (a, b) => {
          return a.financialId == 'Não Faturado'
            ? -1
            : b.financialId == 'Não Faturado'
            ? 1
            : a.financialId - b.financialId;
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Notas Fiscais',
        dataIndex: 'nfses',
        key: 'nfses',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    ];

    const bigNumbers = values.reduce(
      (a, b) => {
        return {
          estimate_value: a.estimate_value + b.estimate_value,
          estimate_revenue: a.estimate_revenue + b.estimate_revenue,
          estimate_pure_value: a.estimate_pure_value + b.estimate_pure_value,
        };
      },
      { estimate_value: 0, estimate_revenue: 0, estimate_pure_value: 0 },
    );
    const totalOrders = data?.freightByFilterParams?.meta?.totalItems;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="button-group">
          <Button
            icon={<DownloadOutlined />}
            className="download"
            onClick={() => {
              saveCsv(
                'freights',
                `Embarcador;Transportador;Destinatário;Número;Data de Criação;Valor Total;Valor Serviço;Valor Transportador;Número da Fatura;Notas Fiscais;Status`,
                (() => {
                  const csvData = [];

                  values.map((item) => {
                    let financialId;
                    item.financial
                      ? (financialId = item.financial.id)
                      : (financialId = 'Não Faturado');

                    csvData.push([
                      item.shipper.name,
                      item.shippingCompany
                        ? item.shippingCompany.fantasy_name ??
                          item.shippingCompany.name ??
                          item.shippingCompany.social_name
                        : '',
                      item.destiny_name ?? '',
                      item.number ?? '',
                      new Date(item.created_at).toLocaleDateString('pt-br') ??
                        '',
                      item.estimate_value ?? '',
                      item.estimate_revenue ?? '',
                      item.estimate_pure_value ?? '',
                      financialId ?? '',
                      item.nfe_serie_number ?? '',
                      item.status ?? '',
                    ]);
                  });

                  return csvData;
                })(),
              );
            }}
          >
            Exportar CSV
          </Button>
          <Button
            icon={<SyncOutlined />}
            className="atualizar"
            onClick={() => {
              checkValidation();
              refetch();
            }}
          >
            Atualizar
          </Button>
        </div>

        <Row gutter={[12, 12]} style={{ marginBottom: 12 }}>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                prefix="R$ "
                title="Valor Total"
                value={bigNumbers.estimate_value.toFixed?.(2)}
              />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                prefix="R$ "
                title="Valor Total de Serviço"
                value={bigNumbers.estimate_revenue.toFixed?.(2)}
              />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                prefix="R$ "
                title="Valor Total de Transportador"
                value={bigNumbers.estimate_pure_value.toFixed?.(2)}
              />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic title="Total de Pedidos" value={totalOrders} />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                title="Valor de desconto"
                value={`R$ ${(
                  discountsData?.getByShipperId
                    ?.map?.(({ value }) => value ?? 0)
                    ?.reduce?.((a, b) => a + b) ?? 0
                ).toFixed?.(2)}`}
              />
            </Card>
          </Col>
        </Row>

        <Table
          className={'FreightTable'}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          size="middle"
          columns={columns}
          dataSource={dataRows}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setSelectedPage(page);
              // setPageSize(pageSize);
            },
            total: meta?.totalItems ?? 0,
            current: selectedPage,
          }}
        />

        <Modal
          title="Faturar"
          visible={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false)
            modalForm.resetFields(['discounts'])            
            setSelectedDiscountValues(0)
          }}
          onOk={() => {
            modalForm.validateFields().then((values) => {
              setLoadingOk(true);
              billFreightsById({
                variables: {
                  freightIds: selectedFreights,
                  fiscalEmission: values.emission_date.format?.('YYYY-MM-DD'),
                  initialDateBill:
                    values.closing_date[0].format?.('YYYY-MM-DD'),
                  finalDateBill: values.closing_date[1].format?.('YYYY-MM-DD'),
                  discounts: values.discounts,
                },
              })
                .catch(() => {
                  refetch()
                  setLoadingOk(false);
                })
                .then(() => {
                  refetch()
                  setIsModalOpen(false);
                  setLoadingOk(false);
                  modalForm.resetFields(['discounts'])
                  setSelectedDiscountValues(0)
                });
            });
          }}
          okButtonProps={{
            disabled:
              (selectedDiscountValues ?? 0) > (formBillValues?.totalValue ?? 0) || loadingOk,
          }}
          confirmLoading={loadingOk}
        >
          <Spin spinning={loadingBill}>
            <Form form={modalForm}>
              <Form.Item
                label="Embarcador"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input disabled value={`${shipperName} (${shipperDocument})`} />
              </Form.Item>
              <Form.Item
                name="emission_date"
                label="Data da emissão fiscal"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <DatePicker
                  placeholder="Data da emissão fiscal"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="discounts"
                label="Selecionar descontos"
                rules={[{ required: false }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Selecionar descontos"
                  disabled={loadingDiscounts}
                  options={
                    discountsData?.getByShipperId?.map?.(
                      ({ id, freight, value }) => ({
                        label: `Pedido #${freight?.number} (R$ ${value?.toFixed?.(2)})`,
                        value: id,
                        discount: value,
                      }),
                    ) ?? []
                  }
                  onChange={(_, options) =>
                    setSelectedDiscountValues(
                      options
                        ?.map?.(({ discount }) => discount ?? 0)
                        ?.reduce?.((a, b) => a + b, 0) ?? 0,
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name="closing_date"
                label="Fechamento"
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Form>
          </Spin>

          <Row>
            <Col span={24}>
              <Typography.Title level={5}>Resumo da fatura</Typography.Title>
            </Col>
            <Col span={24}>
              <Divider orientation="center" />
            </Col>
            <Col span={24}>
              <Row
                justify="space-between"
                style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
              >
                <Typography.Title level={5}>Valor cheio</Typography.Title>
                <Typography.Text>{`R$ ${(
                  formBillValues?.totalValue ?? 0
                )?.toFixed(2)}`}</Typography.Text>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                justify="space-between"
                style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
              >
                <Typography.Title level={5}>Desconto aplicado</Typography.Title>
                <Typography.Text>{`R$ ${(selectedDiscountValues ?? 0)?.toFixed(
                  2,
                )}`}</Typography.Text>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                justify="space-between"
                style={{ padding: '0.5rem 0.5rem 0 0.5rem' }}
              >
                <Typography.Title level={5}>Valor do serviço</Typography.Title>
                <Typography.Text>{`R$ ${(
                  formBillValues?.serviceValue ?? 0
                )?.toFixed(2)}`}</Typography.Text>
              </Row>
            </Col>
            <Col
              span={24}
              style={{
                backgroundColor: '#4787BF',
                borderRadius: 6,
                padding: '0.5rem 0.5rem 0 0.5rem',
              }}
            >
              <Row justify="space-between">
                <Typography.Title style={{ color: 'white' }} level={5}>
                  Total à pagar
                </Typography.Title>
                <Typography.Text style={{ color: 'white' }}>{`R$ ${(
                  (formBillValues?.totalValue ?? 0) -
                  (selectedDiscountValues?.toFixed(2) ?? 0)
                ).toFixed(2)}`}</Typography.Text>
              </Row>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  },
);

export default FreightsTable;
