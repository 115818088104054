import axios from 'axios';

const apiCore = axios.create({
  baseURL: process.env.REACT_APP_API_CORE,
});

export const apiFinancial = axios.create({
  baseURL: process.env.REACT_APP_MS_FINANCIAL_HOST,
});

export default apiCore;
