export const formatDocument = (document = "") => {
	const cleanDocument = document?.replace(/\D/g, "") ?? "";
	if (cleanDocument.length === 14)
		return cleanDocument.replace(
			/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			"$1.$2.$3/$4-$5"
		);

	if (cleanDocument.length === 11)
		return cleanDocument.replace(
			/^(\d{3})(\d{3})(\d{3})(\d{2})/,
			"$1.$2.$3-$4"
		);

	return document;
};