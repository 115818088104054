import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { TreeSelect } from 'antd';
import { SelectedShipperContext } from '../../context/selectedShipperContext';
import { formatDocument } from '../../common/formatDocument';

export default function AutoCompleteSearch({ onSelected, query, disabled, idValue, showBranches = false }) {
  const { data } = useQuery(query, { errorPolicy:'ignore' });
  const [value, setValue] = useState(undefined);

  const options = [];

  useEffect(() => {
    if (data) {
      let found = data.shippers.find((x) => x.id  === value);
      if (!found && showBranches) {
        found = data.shippers.reduce((acc, shipper)=>[...acc, ...(shipper.branches ?? [])],[]).find((branch)=> branch.document === value)
      }
      found ? onSelected(found) : onSelected({ id: '', name: '', document: '' });
    }
  }, [value]);

  useEffect(()=>{
      if(idValue){
        let found = data.shippers.find((x) => x.id  === value);
        if (!found && showBranches) {
          found = data.shippers.reduce((acc, shipper)=>[...acc, ...(shipper.branches ?? [])],[]).find((branch)=> branch.document === value)
        }
        if(found){
          setValue({
            label: `${found.name} (${found.document})` ,
            value: found.id,
          })
        }
    }
  },[idValue])

  const onValue = (option) => {
    setValue(option);
  };

  const handleClear = () => {
    onSelected({ id: '', name: '', document:'' });
  };

  if (data) {
    data.shippers.map((shipper) =>
      options.push({
        label: `${shipper.name} (${shipper.document})` ,
        value: shipper.id,
        children: showBranches ? 
          shipper?.branches?.map(
            (branch)=>({
              label: `${branch.name} (${formatDocument(branch.document)})`, 
              value: branch.document, 
              key:shipper.id + branch.document
            })) :
            null
      }),
    );
  }

  return (
    <TreeSelect
      dropdownMatchSelectWidth={400}
      style={{
        width: 300,
        minWidth: '0',
      }}
      treeData={options}
      onClear={handleClear}
      value={value}
      onChange={onValue}
      disabled={options.length === 0 || disabled}
      allowClear
      showSearch
      treeDefaultExpandAll
      notFoundContent="Nenhum embarcador encontrado"
      placeholder={'Selecione o embarcador'}
      filterOption={(inputValue, option) =>
        option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    ></TreeSelect>
  );
}
