import { useQuery } from '@apollo/client';
import { Descriptions } from 'antd';
// import { GET_SHIPPER_DETAILS } from '../../graphql';

export default function ShipperInfo({ shipperId }) {
  // const { loading, data, error } = useQuery(GET_SHIPPER_DETAILS, {
  //   variables: { shipperId },
  // });

  // if (loading) return <></>;
  // if (error) return <></>;

  // const translateBillingMode = () => {
  //   switch (data.shipper.billingTaskMode) {
  //     case 'monthly':
  //       return 'Mensal';
  //     case 'weekly':
  //       return 'Semanal';
  //     case 'fortnightly':
  //       return 'Quinzenal';
  //     case 'manual':
  //       return 'Manual';
  //     default:
  //       return data.shipper.billingTaskMode;
  //   }
  // };

  return null; //hiding this info for now

  // return (
  //   <Descriptions
  //     title="Embarcador"
  //     style={{
  //       backgroundColor: '#fff',
  //       padding: '20px',
  //       marginTop: '20px',
  //       marginLeft: '0px',
  //       minWidth: '0',
  //     }}
  //     column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
  //   >
  //     <Descriptions.Item label="Nome social">
  //       {data.shipper.social_name}
  //     </Descriptions.Item>
  //     <Descriptions.Item label="Fretes em Projeção">
  //       {data.shipper?.freights?.length}
  //     </Descriptions.Item>
  //     <Descriptions.Item label="Frequencia de faturação">
  //       {translateBillingMode()}
  //     </Descriptions.Item>
  //     <Descriptions.Item label="Fretes em Previsão">
  //       PLACEHOLDER
  //     </Descriptions.Item>
  //     <Descriptions.Item label="Fretes em Efetivação">
  //       PLACEHOLDER
  //     </Descriptions.Item>
  //   </Descriptions>
  // );
}
