import { Table } from 'antd';
import { convertToReal } from '../../common/formatCurrency';
import { baseBucketUrl } from '../../config/client-graphql';

export function FreightsInfoTable({ freights, financial }) {
  const data = [];

  const columns = [
    {
      title: 'Status',
      dataIndex: 'deliveryStatusName',
      key: 'deliveryStatusName',
    },
    {
      title: 'Data de Criação',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Valor Estimado',
      dataIndex: 'estimate_value',
      key: 'estimate_value',
    },
    {
      title: 'Destinatário',
      dataIndex: 'destinyName',
      key: 'destinyName',
    },
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Notas Fiscais',
      dataIndex: 'nfses',
      key: 'nfses',
    }
  ];

  freights.map((freight) => {
    data.push({
      key: freight.id,
      deliveryStatusName: freight.status,
      estimate_value: convertToReal(freight.estimate_value),
      created_at: new Date(freight.created_at).toLocaleDateString('pt-br'),
      destinyName: freight.destiny_name,
      nfses: freight.nfe_serie_number,
      number: freight?.number,
    });
  });

  return (
    <div>
      <h6>Pedidos de Frete</h6>
      <a href={`${baseBucketUrl}/reports/ships/Report-${financial.id}.xlsx`}>
        Baixar Relatório de Envio
      </a>
      <Table
        size="middle"
        dataSource={data}
        columns={columns}
        style={{ marginTop: '20px', minWidth: '0' }}
        scroll={{ x: 480 }}
      />
    </div>
  );
}
