import React, { useContext } from 'react';
import '../css/table.css';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Error from '../error/error';
import {
  Alert,
  Button,
  Table,
  Modal,
  Form,
  Input,
  notification,
  Row,
  Col,
  Dropdown,
  Space,
  Menu,
  Upload,
} from 'antd';
import { SyncOutlined, DownloadOutlined, CaretDownOutlined, UploadOutlined } from '@ant-design/icons';
import {
  FINANCIAL_BY_FILTER_PARAMS,
  GET_FINANCIAL_AGGR_VALUES,
  REPROCESS_FINANCIAL,
  REISSUE_FINANCIAL,
  RESEND_EMAIL,
  LIQUIDATE_FINANCIAL,
  LIQUIDATE_FINANCIAL_STR,
} from '../../graphql';
import { convertToReal } from '../../common/formatCurrency';
import { CustomSpinner } from '../customSpinner/customSpinner';
import { useEffect, useState } from 'react';
import { checkValidation } from '../../services/logout-service';
import saveCsv from '../../services/csv-exporter';
import FinancialAggrValues from '../../components/financialAggrValues/financialAggrValues';
import ReactCurrencyInput from 'react-currency-input';
import { moneyToFloat } from '../../common/parseCurrency';
import { AuthContext } from '../../context/AuthContext/authContext';
import { apiFinancial } from '../../services/login-api'
import { objectToFormData } from '../../common/obect-to-form-data'
import Cookies from 'universal-cookie';
import { formatDocument } from '../../common/formatDocument';

export default function BillingsTable({
  shipperId,
  shipperName,
  shipperDocument,
  billingDocument,
  dateRange,
  status,
  financialId
}) {
  const { user, hasAccess } = useContext(AuthContext);

  const [selectedPage, setSelectedPage] = useState(1);
  const [resendingLoadingBtnId, setResendingLoadingBtnId] = useState('');
  const [reprocessingLoadingBtnId, setReprocessingLoadingBtnId] = useState('');
  const [liquidatingLoading, setLiquidatingLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [modalId, setModalId] = useState(undefined);
  const [form] = Form.useForm();
  const cookies = new Cookies();
  const token = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);

  let after, before;

  shipperName === '' || null ? (shipperName = null) : null;
  shipperDocument === '' || null ? (shipperDocument = null) : null;

  status === '' || null ? (status = null) : null;

  if (dateRange.length <= 1) {
    after = null;
    before = null;
  } else if (dateRange.length > 1) {
    after = dateRange[0];
    before = dateRange[1];
  }

  const { loading, data, error, refetch } = useQuery(
    FINANCIAL_BY_FILTER_PARAMS,
    {
      variables: {
        // shipperId,
        shipperDocument,
        billingDocument,
        status,
        after,
        before,
        selectedPage,
        pageSize,
        financialId: financialId? Number(financialId): undefined
      },
    },
  );

  const aggrQuery = useQuery(GET_FINANCIAL_AGGR_VALUES);

  useEffect(() => {
    refetch();
    aggrQuery.refetch();
  }, [selectedPage, pageSize]);

  const [resendEmail, mutation] = useMutation(RESEND_EMAIL);
  const [reprocessFinancial, reprocessMutation] =
    useMutation(REPROCESS_FINANCIAL);
  const [reissueFinancial, reissueMutation] =
    useMutation(REISSUE_FINANCIAL);

  const [liquidateFinancial, liquidateMutation] =
    useMutation(LIQUIDATE_FINANCIAL);

  const history = useHistory();

  const columns = [
    {
      title: 'Número',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Embarcador',
      dataIndex: 'shipperName',
      key: 'shipperName',
    },
    {
      title: 'Documento matriz',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Documento faturado',
      dataIndex: 'billing_document',
      key: 'billing_document',
      render: (_, record) => record.billing_document ? formatDocument(record.billing_document): record.document,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Data de Vencimento',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text, _record) => <>{text.toLocaleDateString('pt-br')}</>,
      sorter: (a, b) => a.dueDate.getTime() - b.dueDate.getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, _record) => <>{text.toLocaleDateString('pt-br')}</>,
      sorter: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Valor da Nota Fiscal',
      dataIndex: 'nfseValue',
      key: 'nfseValue',
      render: (text, _record) => (
        <>{text != 'Sem nfse' ? convertToReal(text) : text}</>
      ),
      sorter: (a, b) => {
        return a.nfseValue == 'Sem nfse'
          ? -1
          : b.nfseValue == 'Sem nfse'
          ? 1
          : a.nfseValue - b.nfseValue;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Débito',
      dataIndex: 'debitValue',
      key: 'debitValue',
      render: (text, _record) => (
        <>{text != 'Sem débito' ? convertToReal(text) : text}</>
      ),
      sorter: (a, b) => {
        return a.nfseValue == 'Sem débito'
          ? -1
          : b.nfseValue == 'Sem débito'
          ? 1
          : a.nfseValue - b.nfseValue;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Valor do boleto',
      dataIndex: 'boletoValue',
      key: 'boletoValue',
      render: (text, _record) => (
        <>{text != 'Sem boleto' ? convertToReal(text / 100) : 'Sem boleto'}</>
      ),
      sorter: (a, b) => {
        return a.boletoValue == 'Sem boleto'
          ? -1
          : b.boletoValue == 'Sem boleto'
          ? 1
          : a.boletoValue - b.boletoValue;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render:(record) => {
        const actionMenu = (
          <Menu>
            <Menu.Item title='Detalhes' onClick={() => history.push(`/billings/${record.id}`)}>
              Detalhes
            </Menu.Item>

            <Menu.Item
              title='Reenviar'
              disabled={mutation.loading}
              onClick={() => {
                resendEmail({
                  variables: {
                    financial: Number(record.id),
                  },
                }).catch((err) => console.log(err));
                setResendingLoadingBtnId(record.id);
              }}
            >
              Reenviar
            </Menu.Item>

            <Menu.Item
              title='Reprocessar'
              disabled={
                reprocessMutation.loading ||
                (record.boleto && record.nfses > 0)
              }
              onClick={() => {
                reprocessFinancial({
                  variables: {
                    financial: Number(record.id),
                  },
                })
                  .then(() => {
                    refetch();
                    aggrQuery.refetch();
                  })
                  .catch((err) => console.log(err));
                setReprocessingLoadingBtnId(record);
              }}
            >
              Reprocessar
            </Menu.Item>
            { hasAccess('financial.liquidate') && (
              <Menu.Item
                title='Liquidar'
                disabled={
                  liquidateMutation.loading || !record.boleto || !record.nfses > 0
                }
                onClick={() => {
                  setModalId(record.id);
                }}
              >
                Liquidar
              </Menu.Item>
            )}
            <Menu.Item
              title='Reemitir'
              disabled={
                reissueMutation.loading || record.status?.toLowerCase?.() !== 'vencido'
              }
              onClick={() => {
                reissueFinancial({
                  variables: {
                    financialId: Number(record.id),
                  },
                })
                  .then(() => {
                    notification.success({
                      message: 'Boleto reemitido com sucesso',
                      description: 'O boleto antigo foi cancelado e substituido por essa nova emissão'
                    });
                    refetch();
                    aggrQuery.refetch();
                  })
                  .catch((error) => notification.error({ message:'Erro ao reemitr boleto!', description: error.message}));
              }}
            >
              Reemitir
            </Menu.Item>
          </Menu>
        )

        return(
        <Dropdown overlay={actionMenu}>
          <Button>
            <Space>
              Selecione
              <CaretDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )} 
    }
  ];

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomSpinner fontSize={50} />
      </div>
    );

  if (error) return <Error error={error} />;

  const values = data.financialByFilterParams.financials;
  const meta = data.financialByFilterParams.meta;
  const dataRows = [];

  values.map((item) => {
    const createdAt = new Date(item.createdAt);
    const due = new Date(item.createdAt);
    due.setDate(createdAt.getDate() + 7);

    dataRows.push({
      key: item.id,
      details: item.id,
      email: item.id,
      actions: {
        id: item.id,
        nfses: item.value,
        boleto: item.amount ? { amount: item.amount } :undefined,
        status: item.status,
      },
      id: item.id,
      shipperName: item.name,
      document: item.document,
      boletoValue: item.amount ? Number(item.amount) : 'Sem boleto',
      nfseValue: item.value ? Number(item.value) : 'Sem nfse',
      debitValue: item.debitValue ? Number(item.debitValue) : 'Sem débito',
      status: item.status,
      dueDate: due,
      createdAt: createdAt,
      billing_document: item.billing_document,
    });
  });

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {mutation.error ? (
          <Alert
            className="alert-billing"
            description={mutation.error.message}
            type="error"
            closable
            showIcon
          />
        ) : null}

        {mutation.data ? (
          <Alert
            className="alert-billing"
            description="Email reenviado"
            type="success"
            closable
            showIcon
          />
        ) : null}

        {reprocessMutation.error ? (
          <Alert
            className="alert-billing"
            description={reprocessMutation.error.message}
            type="error"
            closable
            showIcon
          />
        ) : null}

        {reprocessMutation.data ? (
          <Alert
            className="alert-billing"
            description="Faturamento reprocessado"
            type="success"
            closable
            showIcon
          />
        ) : null}

        <FinancialAggrValues
          loading={aggrQuery.loading}
          data={aggrQuery.data}
          error={aggrQuery.error}
        />

        <div className="button-group">
          <Button
            icon={<DownloadOutlined />}
            className="download"
            onClick={() => {
              saveCsv(
                'billings',
                `Número;Embarcador;Status;Data de Vencimento;Data de Criação;Valor da Nota Fiscal;Valor do boleto`,
                (() => {
                  const csvData = [];

                  values.map((item) => {
                    const createdAt = new Date(item.createdAt);
                    const due = new Date(item.createdAt);
                    due.setDate(createdAt.getDate() + 2);

                    csvData.push([
                      item.id ?? '',
                      item.name ?? '',
                      item.status ?? '',
                      due.toLocaleDateString('pt-br'),
                      createdAt.toLocaleDateString('pt-br'),
                      item.value ? item.value : 'Sem nfse',
                      item.amount ? item.amount : 'Sem boleto',
                    ]);
                  });

                  return csvData;
                })(),
              );
            }}
          >
            Exportar CSV
          </Button>
          <Button
            icon={<SyncOutlined />}
            className="atualizar"
            onClick={() => {
              checkValidation();
              refetch();
              aggrQuery.refetch();
            }}
          >
            Atualizar
          </Button>
        </div>

        <Table
          size="middle"
          dataSource={dataRows}
          columns={columns.filter(Boolean)}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setSelectedPage(page);
              setPageSize(pageSize);
            },
            total: meta.totalItems,
            current: selectedPage,
          }}
        />
      </div>

      <Modal
        title="Liquidar Fatura"
        visible={modalId != undefined}
        confirmLoading={liquidatingLoading}
        onCancel={() => {
          setModalId(undefined);
          form.resetFields();
        }}
        onOk={() => {
          setLiquidatingLoading(true);
          form.validateFields().then((data) => {
            apiFinancial.post('', objectToFormData({
              operations: JSON.stringify({
                variables: {
                  financialId: Number(modalId),
                  value: moneyToFloat(data.value),
                  observation: data.observation,
                  email: user?.email,
                  image: null
                },
                query: LIQUIDATE_FINANCIAL_STR,
              }),
              map: '{ "nfile": ["variables.image"] }',
              nfile: data.image,
            }),
            { 
              headers: { 
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
              } 
            }
            )
            .then(() => {
                notification.success({
                  message: 'Fatura liquidada com sucesso!',
                  description: '',
                });
              })
              .catch(() => {
                notification.error({
                  message: 'Erro ao liquidar fatura!',
                  description: 'Tente novamente mais tarde',
                });
              })
              .finally(() => {
                setLiquidatingLoading(false);
                setModalId(undefined);
                form.resetFields();
              });
          });
        }}
      >
        <Form form={form} layout="vertical">
          <Row>
            <Col span={12}>
              <Form.Item
                name="value"
                label="Valor"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                    whitespace: true,
                  },
                ]}
              >
                <ReactCurrencyInput
                  className="ant-input"
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix="R$ "
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="observation"
                label="Observação"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea  placeholder='Observação' rows={4}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="image"
                label="Comprovante de liquidação"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
                getValueFromEvent={({file})=> file.status === 'done' ? file.originFileObj: undefined}
              >
                <Upload.Dragger
                  multiple={false} 
                  customRequest={({ onSuccess }) => onSuccess("ok")}
                  maxCount={1}
                  accept=".pdf, image/*"
                >
                  <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">Clique ou arraste o comprovante para realiza upload </p>
                  <p className="ant-upload-hint">
                    Apenas imagens ou arquivos com extensão .pdf
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
