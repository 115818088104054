import { useMutation } from '@apollo/client';
import { Alert, Button, Input } from 'antd';
import { useContext, useRef, useState, useMemo } from 'react';
import AutoCompleteSearch from '../components/autoCompleteSearch/autoCompleteSearch';
import '../components/css/freightsTable.css';
import { CustomSpinner } from '../components/customSpinner/customSpinner';
import DateRangePicker from '../components/dateRangePicker/dateRangePicker';
import FreightsTable from '../components/freightsTable/freightsTable';
import ShipperInfo from '../components/shipperInfo/shipperInfo';
import { StatusSelect } from '../components/statusSelect/statusSelect';
import { SelectedShipperContext } from '../context/selectedShipperContext';
import { BILL_FREIGHTS_BY_ID_NEW, GET_SHIPPER_NAMES } from '../graphql';
import { AuthContext } from '../context/AuthContext/authContext';
import './css/root.css';
import BranchSelect from '../components/branchSelect/branchSelect';

const { Search } = Input;

function Freights() {
  const [billFreightsById, { loading, data, error }] =
    useMutation(BILL_FREIGHTS_BY_ID_NEW);
  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Aguardando Faturamento');
  const [selectedFreights, setSelectedFreights] = useState([]);
  const [inputShipperDocument, setInputShipperDocument] = useState('');
  const [shipperDocument, setShipperDocument] = useState('');
  const tableRef = useRef();

  const { selectedShipper, setSelectedShipper, selectedBranch, setSelectedBranch } = useContext(SelectedShipperContext);
  const { hasAccess } = useContext(AuthContext);

  const handleRefetch = () => {
    tableRef.current.clearSelection();
    tableRef.current.refetchData();
  };

  const onStatusSelected = (value) => {
    setSelectedStatus(value);
  };

  const onShipperSelected = (value) => {
    setSelectedShipper(value);
  };

  const onBranchSelected = (value) => {
    setSelectedBranch(value);
  };

  const onDatePicked = (date) => {
    setSelectedRange(date);
  };

  const onFreightSelect = (freights) => {
    setSelectedFreights(freights);
  };

  const handleBillButton = () => {
  tableRef.current.openModal();
    // selectedFreights.length > 0
    //   ? billFreightsById({
    //       variables: {
    //         selectedFreights: selectedFreights,
    //       },
    //     })
    //       .catch(() => handleRefetch())
    //       .then(() => handleRefetch())
    //   : null;
  };

  const handleAllBillButton = () => {
    const allIds = tableRef.current.getAllBillIds();
    billFreightsById({
        variables: {
          selectedFreights: allIds,
        },
      })
        .catch(() => handleRefetch())
        .then(() => handleRefetch())
    }

  const handleCpfCnpjChange = (event) => {
    // Get only the numbers from the data input
    let data = event.target.value.replace(/\D/g, "");
    // Checking data length to define if it is cpf or cnpj
    if (data.length > 11) {
      // It's cnpj
      let cnpj = `${data.substr(0, 2)}.${data.substr(2,3)}.${data.substr(5,3)}/`;
      if (data.length > 12)
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      else 
        cnpj += data.substr(8);
      // Pass formatting for the data
      data = cnpj;
    } else {
      // It's cpf
      let cpf = "";
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
      }
      // Pass formatting for the data
      data = cpf;
    }
    // Update state
    setInputShipperDocument(data);
  };

  const statusSelectOptions = [
    'Novo Pedido',
    'Aguardando Auditoria',
    'Aguardando Faturamento',
    'Faturado',
    'Vencido',
    'Liquidado',
    'Repassando',
    'Repassado',
  ];
  

  return (
    <div className="page-wrapper">
      {error ? (
        <Alert description={error.message} type="error" closable showIcon />
      ) : null}

      {data ? (
        <Alert
          description="Fretes faturados"
          type="success"
          closable
          showIcon
        />
      ) : null}

      <div className="content-wrapper">
        <h6>Pedidos de Fretes</h6>
        <div className="filters-wrapper">
          <div
            style={{
              display: 'inline-flex',
              flexWrap: 'nowrap',
              minWidth: '0',
              gap: '1.7%',
            }}
          >
            <DateRangePicker onDatePicked={onDatePicked} />
            <StatusSelect
              onStatusSelected={onStatusSelected}
              options={statusSelectOptions}
              defaultOption={statusSelectOptions[2]}
            />
            <AutoCompleteSearch
              onSelected={onShipperSelected}
              query={GET_SHIPPER_NAMES}
            />
            {(selectedShipper.branches?.length > 0) &&(
              <BranchSelect selectedShipper={selectedShipper} onSelected={onBranchSelected}/>
            )}
          </div>
         { hasAccess('freight_order.bill') && (
            <Button
              onClick={handleBillButton}
              type="primary"
              disabled={loading || selectedFreights.length === 0 ? true : false}
            >
              {loading ? (
                <span>
                  <CustomSpinner fontSize={12} marginTop='0px'/>
                  Faturando
                </span>
              ) : (
                <>Faturar Selecionados</>
              )}
            </Button>
         )}
          {/* { hasAccess('freight_order.bill') && (
            <Button
              onClick={handleAllBillButton}
              type="primary"
              disabled={disableBillAll}
              title="Só é possível faturar todos a partir de um CNPJ!"
            >
              {loading ? (
                <span>
                  <CustomSpinner fontSize={12} marginTop='0px'/>
                  Faturando
                </span>
              ) : (
                <>Faturar Todos</>
              )}
            </Button>
         )} */}
        </div>
        {selectedShipper.id != '' ? (
          <ShipperInfo shipperId={selectedShipper.id} />
        ) : (
          <></>
        )}

        <FreightsTable
          ref={tableRef}
          freightsQueryParams={{
            shipperId: selectedShipper.id,
            shipperName: selectedShipper.name,
            dateRange:
              selectedRange.length > 0
                ? selectedRange
                : [],
            deliveryStatusName: selectedStatus,
            shipperDocument: selectedShipper.document,
            billingDocument: selectedBranch.document,
          }}
          className="freightsTable"
          onFreightSelect={onFreightSelect}
          billFreightsById={billFreightsById}
          loadingBill={loading}
        />
      </div>
    </div>
  );
}

export default Freights;
