import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { GET_FINANCIAL_BY_ID } from '../graphql';
import ShipperInfo from '../components/shipperInfo/shipperInfo';
import { FinancialInfo } from '../components/financialInfo/financialInfo';
import { NfsesTable } from '../components/nfsesTable/nfsesTable';
import { BoletoInfo } from '../components/boletoInfo/boletoInfo';
import { FreightsInfoTable } from '../components/freightsInfoTable/freightsInfoTable';
import { DebitInfo } from '../components/debitInfo/debitInfo';
import { CustomSpinner } from '../components/customSpinner/customSpinner';
import Error from '../components/error/error';
import './css/root.css';

export default function BillDetails() {
  const { selectedBill } = useParams();
  const id = Number(selectedBill);

  const { loading, data, error } = useQuery(GET_FINANCIAL_BY_ID, {
    variables: { id },
  });

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomSpinner fontSize={50} />
      </div>
    );

  if (error) return <Error error={error} />;

  const financial = data.financial;

  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h6>Detalhes da Fatura</h6>

        <div
          style={{ display: 'flex', flexDirection: 'column', minWidth: '0' }}
        >
          <FinancialInfo financial={financial} />

          <ShipperInfo shipperId={financial.shipper.id} />

          { financial.boleto 
            ? (<BoletoInfo boleto={financial.boleto} />)
            : null
          }

          <DebitInfo financial={financial} />

          <NfsesTable nfses={financial.nfses} />

          <FreightsInfoTable freights={financial.freights} financial={financial} />
        </div>
      </div>
    </div>
  );
}
