import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
const { Sider } = Layout;
import { useHistory } from 'react-router-dom';
import { logoutService } from '../../services/logout-service';
import '../css/sidebar.css';
import {
  ShoppingCartOutlined,
  LogoutOutlined,
  CopyOutlined,
  FileDoneOutlined,
  ContainerOutlined,
  DollarCircleOutlined
} from '@ant-design/icons';
import { AuthContext } from '../../context/AuthContext/authContext';

export default function Sidebar() {
  const history = useHistory();
  const { signOut } = useContext(AuthContext);

  return (
    <Sider width={200} breakpoint="md" collapsedWidth="50">
      <Menu
        mode="inline"
        theme="light"
        defaultSelectedKeys={['1']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item
          onClick={() => history.push('/')}
          key="1"
          className="MenuItem"
          icon={<ShoppingCartOutlined />}
        >
          Fretes
        </Menu.Item>
        <Menu.Item
          onClick={() => history.push('/billings')}
          key="2"
          className="MenuItem"
          icon={<FileDoneOutlined />}
        >
          Faturamentos
        </Menu.Item>

        <Menu.Item
          onClick={() => history.push('/boletos')}
          key="3"
          className="MenuItem"
          icon={<CopyOutlined />}
        >
          Boletos
        </Menu.Item>

        <Menu.Item
          onClick={() => history.push('/discounts')}
          key="desconto"
          className="MenuItem"
          icon={<DollarCircleOutlined />}
        >
          Descontos
        </Menu.Item>

        <Menu.Item
          onClick={() => history.push('/shippers')}
          key="4"
          className="MenuItem"
          icon={<ContainerOutlined />}
        >
          Embarcadores
        </Menu.Item>

        <Menu.Item onClick={signOut} key="5" icon={<LogoutOutlined />}>
          Sair
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
