import { Table, notification } from 'antd';
import { convertToReal } from '../../common/formatCurrency';

export function NfsesTable({ nfses }) {
  const data = [];
  let totalNfseValue = 0;

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Pdf',
      dataIndex: 'pdf',
      key: 'pdf',
      render: (text, record, index) => {
        const isProcessing = record.status?.toString?.().toLowerCase() === 'processando';
        return (
          <a
            href={`${isProcessing ? '#': text}`}
            onClick={(e)=>{
              if(isProcessing){
                e.preventDefault()
                notification.warning({
                  message: 'Boletos com status processando não podem ser impressos!',
                  description: 'Aguarde até que o boleto seja processado para ter acesso ao seu pdf'
                })
              } 
            }}
            style={ isProcessing ? { cursor:'not-allowed' }: undefined}
          >
            Baixar Pdf
          </a>
        );
      },
    },
  ];

  const capitalizeFirstLetter = (string) => {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  nfses.map((nfse) => {
    totalNfseValue += nfse.value;
    data.push({
      status: capitalizeFirstLetter(nfse.status),
      value: convertToReal(nfse.value),
      pdf: nfse.pdf,
      emission: new Date(nfse.emission).toLocaleDateString('pt-br'),
    });
  });

  return (
    <div style={{ marginTop: '20px' }}>
      <h6>Notas Fiscais</h6>
      <p>Valor total das Notas fiscais: {convertToReal(totalNfseValue)}</p>
      <Table
        size="middle"
        dataSource={data}
        columns={columns}
        style={{ marginTop: '20px', minWidth: '0' }}
        scroll={{ x: 480 }}
      />
    </div>
  );
}
