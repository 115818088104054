import { Layout } from 'antd';
import '../css/topbar.css';
const { Header } = Layout;

export default function Topbar() {
  return (
    <Header className="header" style={{ backgroundColor: ' #fff' }}>
      <div className="logo">
        <a href="/">
          <img src="https://smartenvios.s3.sa-east-1.amazonaws.com/logo/svg/logo-horizontal-fundo-claro.svg" />
        </a>
      </div>
    </Header>
  );
}
