import { createContext, useCallback, useEffect, useState } from 'react';

import { renewTokenRequest } from './authContext.utils';

import Cookies from 'universal-cookie';

export const AuthContext = createContext({});
const TOKEN_COOKIE_KEY = `smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`;

export const AuthProvider= ({ children }) => {
  const cookies = new Cookies();

  const [user, setUser] = useState(null);
  const [userPermissionCodes, setUserPermissionCodes] = useState([]);

  const isAuthenticated = !!user;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRenew, setIsLoadingRenew] = useState(false);

  const _setTokenCookie = useCallback((token) => {
    cookies.set(TOKEN_COOKIE_KEY, token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  }, []);

  const _setUserLocalStorage = useCallback((userLocalStorage) => {
    window.localStorage.setItem(
      '@smartEnvios/user',
      JSON.stringify(userLocalStorage)
    );
  }, []);

  const saveCredentials = useCallback(
    ({ token, user: userResponse }) => {
      _setTokenCookie(token);

      _setUserLocalStorage(userResponse);

      setUser(userResponse);
    },
    [_setTokenCookie, _setUserLocalStorage]
  );

  const signOut = useCallback(() => {
    window.localStorage.clear();
    setUser(null);

    if(cookies.get(TOKEN_COOKIE_KEY)){
      cookies.remove(TOKEN_COOKIE_KEY, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
      document.location.href = '/';
    }
  }, []);

  const _renewToken = useCallback(async () => {
    try {
      setIsLoadingRenew(true);
      const renewResponse = await renewTokenRequest();
      saveCredentials(renewResponse);
      setIsLoadingRenew(false);
    } catch (e) {
      signOut();
      setIsLoadingRenew(false);
    }
  }, [saveCredentials, setIsLoadingRenew, signOut]);

  const hasAccess = useCallback(
    (...permissions) => {
      return userPermissionCodes.some((p) => permissions.includes(p));
    },
    [userPermissionCodes]
  );

  useEffect(()=>{
    if(user?.permissions){
      setUserPermissionCodes(user?.permissions?.map(({code})=> code))
    }
  },[user])

  useEffect(() => {
    const userLocalStorage = window.localStorage.getItem('@smartEnvios/user');
    const token = cookies.get(TOKEN_COOKIE_KEY);

    if (userLocalStorage) setUser(JSON.parse(userLocalStorage));

    if (userLocalStorage || token) _renewToken();
    else signOut();
  }, [_renewToken, signOut]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isLoadingRenew,
        isAuthenticated,
        saveCredentials,
        signOut,
        user,
        hasAccess
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
