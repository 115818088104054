import React, { useEffect, useState, useContext } from 'react';
import apiCore from '../../services/login-api';
import '../css/login.css';
import logo from '../../assets/oficiallogo.png';
import { AuthContext } from '../../context/AuthContext/authContext';

const LoginComponent = () => {
  //  To access cookies
  const { saveCredentials } = useContext(AuthContext);

  //  Inputs states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //  Email and password error state
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  //  Reponse error
  const [responseError, setResponseError] = useState('');

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
    setResponseError('');
  };

  //  Handle the login event, try to get the user in the
  //  smartenvios system using the forms passed user
  //  credential
  const handleLogin = () => {
    //  Clear all previous errors that were set
    clearErrors();

    //  Not called inside the 'if' condition to avoid
    //  not show all errors, because if the first one
    //  fail, the second one will not be called.
    let check1 = validadeEmail();
    let check2 = validatePassword();

    if (check1 && check2) {

      //  Colocar o token aqui
      let token = false;

      // For test purpose only
      // token = 'b144d03f-fd2a-4e0e-b7db-39eac110e1c8';

      apiCore
        .post('/auth/cms/login/v2', {
          email: email,
          password: password,
        })
        .then((response) => {
          token = response.data.result.token;

          if (!token) {
            setResponseError('Algo deu errado');
          } else {
            saveCredentials({ token, user: response.data.result.user })
            if(document.location.path !== '/')
              document.location.href = '/';
          }
        })
        .catch((err) => {
          setPasswordError('Senha ou usuário inválidos');
        });
    }
  };

  //  Validates the password input
  const validatePassword = () => {
    let valid = true;

    if (password === '') {
      setPasswordError('Insira uma senha.');
      valid = false;
    }

    return valid;
  };

  //  Validates the email input
  const validadeEmail = () => {
    let valid = true;

    if (email === '') {
      setEmailError('Insira um email');
      valid = false;
    } else if (typeof email !== 'undefined') {
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        setEmailError('Email inválido.');
        valid = false;
      }
    }

    return valid;
  };

  const handleOnSubmit = (event) => {
    handleLogin();

    event.preventDefault();
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <section className="login">
        <div className="login-container">
          <label className="logo-img">
            <img src={logo}></img>
          </label>
          <p className="login-error-msg-high">{responseError}</p>
          <label className="login-label">E-mail</label>
          <input
            name='email-field'
            className="login-input"
            type="email"
            autoFocus
            autoComplete='on'
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p className="login-error-msg">{emailError}</p>
          <label className="login-label">Senha</label>
          <input
            className="login-input"
            type="password"
            autoFocus
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="login-error-msg">{passwordError}</p>
          <div className="login-btn-container">
            <button
              className="login-btn"
              type='submit'
            >
              {' '}
              Entrar{' '}
            </button>
          </div>
        </div>
      </section>
    </form>
  );
};

export default LoginComponent;
