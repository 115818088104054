import Cookies from 'universal-cookie';
import apiCore from '../../services/login-api';

export async function renewTokenRequest() {
    const cookies = new Cookies();
    let token = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);
  
    if (!token) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
  
      token = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);
    }
  
    const { data } = await apiCore.post(
      '/auth/cms/renew/v2',
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  
    const user = data.result.user;
  
    return { token, user };
  }
