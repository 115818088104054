import { DatePicker, Space } from 'antd';
import { useEffect, useState } from 'react';

const { RangePicker } = DatePicker;


export default function DateRangePicker({ onDatePicked, dateFormatOut = 'YYYY-MM-DD HH:mm:ss',dateFormat = 'DD-MM-YYYY' }) { const [range, setRange] = useState([]);

  useEffect(() => {
    onDatePicked(range);
  }, [range]);

  function onChange(date, dateString) {
    if (date) {
      setRange([
        date[0].startOf('day').format(dateFormatOut),
        date[1].endOf('day').format(dateFormatOut)
      ]);
    } else {
      setRange([]);
    }
  }

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        showTime
        onChange={onChange}
        style={{
          minWidth: '0',
        }}
        format={dateFormat}
      />
    </Space>
  );
}
