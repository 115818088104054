import { gql } from '@apollo/client';

export const GET_FREIGHTS = gql`
  query {
    freights {
      id
      external_id
      shipper {
        id
        name
      }
      created_at
      estimate_value
      estimate_pure_value
      nfse {
        idNotazz
      }
    }
  }
`;

export const GET_SHIPPER_DETAILS = gql`
  query ($shipperId: String!) {
    shipper(id: $shipperId) {
      id
      social_name
      frequency
      freights {
        id
      }
    }
  }
`;

export const GET_SHIPPER_NAMES = gql`
  query {
    shippers {
      id
      name
      document
      branches {
        name
        document
      }
    }
  }
`;

export const GET_NFSE = gql`
  query {
    getAllNFSe {
      id
    }
  }
`;

export const GET_FINANCIAL_AGGR_VALUES = gql`
  query {
    getFinancialAggrValues {
      paid
      totalValue
      inDateValue
      outDateValue
    }
  }
`;

// export const GET_SHIPPER_AGGREGATION = gql`
//   query {
//     getShipperAggregations {
//       predictionValue
//       projectionValue
//       effectivationValue
//       id
//       created_at
//       fantasy_name
//       social_name
//     }
//   }
// `;

export const GET_SHIPPER_AGGREGATION = gql`
  query GetShipperAggregations(
    $selectedPage: Float
    $pageSize: Float
    $document: String
  ) {
    getShipperAggregations(
      shipperPaginationDto: {
        page: $selectedPage
        itemsPerPage: $pageSize
        document: $document
      }
    ) {
      shippers {
        predictionValue
        projectionValue
        effectivationValue
        effectivationServiceValue
        effectivationDebitValue
        id
        created_at
        next_financial_closure_day
        fantasy_name
        name
        document
        discountValue
        branches {
          name
          document
          branch_invoice
          effectivationValue
          effectivationServiceValue
          effectivationDebitValue
        }
      }
      meta {
        totalItems
      }
    }
  }
`;

export const BILL_FREIGHTS_BY_ID = gql`
  mutation BillFreightsById($selectedFreights: [String!]!) {
    billFreightsById(ids: $selectedFreights) {
      id
    }
  }
`;
export const BILL_SHIPPER = gql`
  mutation BillShipper(
    $shipperId: String!
    $fiscalEmission: String!
    $initialDateBill: String!
    $finalDateBill: String!
    $discounts: [String!]
  ) {
    billShipper(
      billInfo: {
        shipperId: $shipperId
        initialDateBill: $initialDateBill
        finalDateBill: $finalDateBill
        fiscalEmission: $fiscalEmission
        discounts: $discounts
      }
    ) {
      id
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation SendEmail($financial: Int!) {
    sendEmail(id: $financial)
  }
`;

export const REPROCESS_FINANCIAL = gql`
  mutation ReprocessFinancial($financial: Int!) {
    reprocessFinancial(id: $financial) {
      id
    }
  }
`;

export const REISSUE_FINANCIAL = gql`
  mutation ReissueBoleto($financialId: Float!) {
    reissueBoleto(financialId: $financialId) {
      id
    }
  }
`;

export const LIQUIDATE_FINANCIAL_STR = `
  mutation LiquidateFinancial(
    $financialId: Float!
    $value: Float!
    $observation: String!
    $email: String!
    $image: Upload!
  ) {
    liquidateFinancial(
      financialId: $financialId
      value: $value
      observation: $observation
      email: $email
      image: $image
    ) {
      id
    }
  }
`

export const LIQUIDATE_FINANCIAL = gql(LIQUIDATE_FINANCIAL_STR)

export const FINANCIAL_BY_FILTER_PARAMS = gql`
  query FinancialByFilterParams(
    $shipperId: String
    $status: String
    $after: String
    $before: String
    $selectedPage: Float
    $pageSize: Float
    $financialId: Float
    $document: String
    $shipperDocument: String
    $billingDocument: String
  ) {
    financialByFilterParams(
      filterFinancialDto: {
        shipperId: $shipperId
        status: $status
        after: $after
        before: $before
        page: $selectedPage
        itemsPerPage: $pageSize
        financialId: $financialId
        document: $document
        shipperDocument: $shipperDocument
        billingDocument: $billingDocument
  }
    ) {
      financials {
        id
        status
        dueDate
        createdAt
        name
        billing_document
        document
        amount
        debitValue
        value
      }

      meta {
        totalItems
      }
    }
  }
`;

export const GET_FINANCIAL_BY_ID = gql`
  query ($id: Int!) {
    financial(id: $id) {
      id
      status
      dueDate
      debitValue
      createdAt
      debitPdfId
      summaryPdfId
      shipper {
        id
      }
      freights {
        id
        created_at
        estimate_value
        status
        destiny_name
        nfe_serie_number
        number
      }
      boleto {
        id
        status
        amount
        createdAt
      }
      nfses {
        id
        status
        value
        pdf
        emission
      }
    }
  }
`;
export const FREIGHT_BY_FILTER_PARAMS = gql`
  query (
    $shipperName: String
    $deliveryStatusName: String
    $after: String
    $before: String
    $selectedPage: Float
    $pageSize: Float
    $document: String
    $shipperDocument: String
    $billingDocument: String
  ) {
    freightByFilterParams(
      filterFreightDto: {
        shipperName: $shipperName
        status: $deliveryStatusName
        after: $after
        before: $before
        page: $selectedPage
        itemsPerPage: $pageSize
        document: $document
        shipperDocument: $shipperDocument
        billingDocument: $billingDocument
      }
    ) {
      freights {
        id
        status
        destiny_name
        nfe_serie_number
        estimate_revenue
        estimate_pure_value
        number
        created_at
        estimate_value
        shipping_name
        shippername
        shipperdocument
        billing_document
        financialId
        boleto {
          id
        }
        nfse {
          id
        }
        financial {
          id
        }
      }

      meta {
        totalItems
      }
    }
  }
`;

export const FREIGHT_BY_FILTER_PARAMS_ALL = gql`
  query (
    $shipperName: String
    $deliveryStatusName: String
    $after: String
    $before: String
    $selectedPage: Float
    $pageSize: Float
    $document: String
  ) {
    freightByFilterParams(
      filterFreightDto: {
        shipperName: $shipperName
        status: $deliveryStatusName
        after: $after
        before: $before
        page: $selectedPage
        itemsPerPage: $pageSize
        document: $document
      }
    ) {
      freights {
        id
        estimate_value
        estimate_revenue
        estimate_pure_value
      }
      meta {
        totalItems
      }
    }
  }
`;

export const BOLETOS_BY_FILTER_PARAMS = gql`
  query (
    $shipperName: String
    $status: String
    $after: String
    $before: String
    $selectedPage: Float
    $pageSize: Float
    $document: String
    $shipperDocument: String
    $billingDocument: String
  ) {
    boletoByFilterParams(
      filterBoletoDto: {
        shipperName: $shipperName
        status: $status
        after: $after
        before: $before
        page: $selectedPage
        itemsPerPage: $pageSize
        document: $document
        shipperDocument: $shipperDocument
        billingDocument: $billingDocument
      }
    ) {
      boletos {
        id
        status
        amount
        createdAt
        financialId
        billing_document
        shipper {
          name
          document
        }
      }
      meta {
        totalItems
      }
    }
  }
`;

//#region new
export const BILL_FREIGHTS_BY_ID_NEW = gql`
  mutation BillFreightsById(
    $freightIds: [String!]!
    $fiscalEmission: String!
    $initialDateBill: String!
    $finalDateBill: String!
    $discounts: [String!]
  ) {
    billFreightsById(
      billInfo: {
        freightIds: $freightIds
        initialDateBill: $initialDateBill
        finalDateBill: $finalDateBill
        fiscalEmission: $fiscalEmission
        discounts: $discounts
      }
    ) {
      id
    }
  }
`;

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount(
    $shipperId: String!
    $freightNumber: String!
    $value: Float!
    $observation: String!
    $userEmail: String!
  ) {
    createDiscount(
      shipperId: $shipperId
      freightNumber: $freightNumber
      value: $value
      observation: $observation
      userEmail: $userEmail
    ) {
      id
    }
  }
`;

export const EDIT_DISCOUNT = gql`
  mutation EditDiscount(
    $id: String!
    $shipperId: String!
    $value: Float!
    $observation: String!
    $userEmail: String!
  ) {
    updateDiscount(
      id: $id
      shipperId: $shipperId
      value: $value
      observation: $observation
      userEmail: $userEmail
    ) {
      id
    }
  }
`;

export const DELETE_DISCOUNT = gql`
  mutation DeleteDiscount($id: String!) {
    deleteDiscount(id: $id) {
      id
    }
  }
`;

export const DISCOUNTS_BY_FILTER_PARAMS = gql`
  query (
    $page: Float!
    $itemsPerPage: Float!
    $isApplied: Boolean
    $shipperId: String
    $after: String
    $before: String
    $freightOrderId: String
    $document: String
    $shipperDocument: String
    $billingDocument: String
  ) {
    getAllDiscounts(
      data: {
        page: $page
        itemsPerPage: $itemsPerPage
        isApplied: $isApplied
        shipperId: $shipperId
        after: $after
        before: $before
        freightOrderId: $freightOrderId
        document: $document
        shipperDocument: $shipperDocument
        billingDocument: $billingDocument
      }
    ) {
      discounts {
        id
        observation
        billing_document
        name
        number
        document
        financialId
        userEmail
        value
        shipperId
        createdAt
        isApplied
        __typename
      }
      meta {
        totalItems
      }
    }
  }
`;

export const GET_DISCOUNTS_BY_SHIPPER = gql`
  query ($shipperIds: [String!]!) {
    getByShipperId(shipperIds: $shipperIds) {
      id
      observation
      freight {
        id
        number
      }
      financialId
      userEmail
      value
      shipperId
      createdAt
      __typename
    }
  }
`;
//#endregion new
