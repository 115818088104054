import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, notification, Popconfirm, Row, Spin, Table } from 'antd';
import { useContext, useState, useEffect } from 'react';
import AutoCompleteSearch from '../components/autoCompleteSearch/autoCompleteSearch';
import '../components/css/freightsTable.css';
import DateRangePicker from '../components/dateRangePicker/dateRangePicker';
import ShipperInfo from '../components/shipperInfo/shipperInfo';
import { StatusSelect } from '../components/statusSelect/statusSelect';
import { SelectedShipperContext } from '../context/selectedShipperContext';
import { GET_SHIPPER_NAMES, DISCOUNTS_BY_FILTER_PARAMS, CREATE_DISCOUNT, EDIT_DISCOUNT, DELETE_DISCOUNT } from '../graphql';
import { AuthContext } from '../context/AuthContext/authContext';
import './css/root.css';
import { DeleteFilled, ZoomInOutlined, EditFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import apiCore from '../services/login-api';
import Cookies from 'universal-cookie';
import ReactCurrencyInput from 'react-currency-input';
import moment from 'moment';
import { formatDocument } from '../common/formatDocument';
import BranchSelect from '../components/branchSelect/branchSelect';

const { Search } = Input;

function Discounts() {
  const cookies = new Cookies();
  let token = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);
  const { user } = useContext(AuthContext);

  const [selectedRange, setSelectedRange] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [searchFreightOrder, setSearchFreightOrder] = useState('');
  const [orderIdSearch, setOrderIdSearch] = useState(undefined);

  const { selectedShipper, setSelectedShipper, selectedBranch, setSelectedBranch } = useContext(SelectedShipperContext);

  const [pagination, setPagination] = useState({pageSize: 10, current: 1});
  const [modalMode, setModalMode] = useState(undefined);
  const [modalForm] = Form.useForm();

  const fetchOrder = async (order_number, setter) =>{
    setLoadingOrder(true);
    try{
      const { data:{ result } } = await apiCore.get(
        `/cms/freight-orders/order-details/${order_number}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setter(result.id);
    }catch{
      if(order_number?.length)
        setter(' ');
      else setter(undefined);
    }
    setLoadingOrder(false);
  }

  const { loading, data, error, refetch } = useQuery(
    DISCOUNTS_BY_FILTER_PARAMS,
    {
      variables: {
        // shipperId: selectedShipper.id,
        shipperDocument: selectedShipper.document,
        billingDocument: selectedBranch.document,
        isApplied: selectedStatus === 'Desconto aplicado',
        after: selectedRange[0],
        before: selectedRange[1],
        page: pagination.current -1 ,
        itemsPerPage: pagination.pageSize,
        freightOrderId: orderIdSearch
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [selectedShipper, selectedStatus, selectedRange, orderIdSearch])
  

  const onStatusSelected = (value) => {
    setSelectedStatus(value);
  };

  const onShipperSelected = (value) => {
    setSelectedShipper(value);
  };

  const onDatePicked = (date) => {
    setSelectedRange(date);
  };

  const statusSelectOptions = [
    'Desconto aplicado',
    'Desconto não aplicado'
  ];

  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [orderNumber, setOrderNumber] = useState(undefined);
  const [editId, setEditId] = useState(undefined);
  const [editShipperId, setEditShipperId] = useState(undefined);

  const [createDiscount] =
  useMutation(CREATE_DISCOUNT);

  const [editDiscount] =
  useMutation(EDIT_DISCOUNT);

  const submitOrder = (type, data) =>{
    setLoadingSubmit(true);
    if(type === 'new'){
      createDiscount({
        variables: {
          shipperId: data.shipperId,
          freightNumber: orderNumber,
          value: parseFloat(
            data.value?.toString?.().replace('.', '')
            .replace(',', '.')
            .replace('R$', '')
            .trim()
          ),
          observation: data.observation,
          userEmail: user?.email,
        },
      })
      .then(()=>{
        notification.success({
          message:'Desconto criado com sucesso!'
        })
        setModalMode(undefined);
        modalForm.resetFields();
        setLoadingSubmit(false);
        refetch();
      })
      .catch((e)=>{
        notification.error({
          message:'Erro ao criar desconto!',
          description: e.message,
        })
        setLoadingSubmit(false);
        refetch();
      })
    }
    if(type === 'edit'){
      editDiscount({
        variables: {
          id: editId,
          shipperId: data.shipperId,
          value: parseFloat(
            data.value?.toString?.().replace('.', '')
            .replace(',', '.')
            .replace('R$', '')
            .trim()
          ),
          observation: data.observation,
          userEmail: user?.email,
        },
      })
      .then(()=>{
        notification.success({
          message:'Desconto editado com sucesso!'
        })
        setModalMode(undefined);
        modalForm.resetFields();
        setLoadingSubmit(false);
        refetch();
      })
      .catch((e)=>{
        notification.error({
          message:'Erro ao editar desconto!',
          description: e.message,
        })
        setLoadingSubmit(false);
        refetch();
      })
    }
  }
  
  const [deleteDiscount] =
    useMutation(DELETE_DISCOUNT);

  return (
    <div className="page-wrapper">
      <div className="content-wrapper">
        <h6>Descontos</h6>
        <div className="filters-wrapper">
          <div
            style={{
              display: 'inline-flex',
              flexWrap: 'nowrap',
              minWidth: '0',
              gap: '1.7%',
            }}
          >
            <Search
              value={searchFreightOrder}
              placeholder='Pedido' 
              onChange={e => setSearchFreightOrder(e.target.value)}
              onSearch={e => setSearchFreightOrder(e)}
              onBlur={()=>{
                fetchOrder(searchFreightOrder, setOrderIdSearch);
              }}
              onPressEnter={(e)=>{
                setSearchFreightOrder(e.target.value)
                fetchOrder(e.target.value, setOrderIdSearch);
              }}
              style={{ width: '30%'}}
            />
            <AutoCompleteSearch
              onSelected={onShipperSelected}
              query={GET_SHIPPER_NAMES}
            />
            {(selectedShipper.branches?.length > 0) &&(
              <BranchSelect selectedShipper={selectedShipper} onSelected={(value) => setSelectedBranch(value)}/>
            )}
            <StatusSelect
              onStatusSelected={onStatusSelected}
              options={statusSelectOptions}
            />
            <DateRangePicker onDatePicked={onDatePicked} dateFormat='YYYY-MM-DD'/>

          </div>
          <Button
            onClick={()=>setModalMode('new')}
            type="primary"
            disabled={loading}
            loading={loading}
          >
            Adicionar novo desconto
          </Button>
        </div>
        {selectedShipper.id != '' ? (
          <ShipperInfo shipperId={selectedShipper.id} />
        ) : (
          <></>
        )}

        <Table
          loading={loading}
          style={{marginTop: 12}} 
          columns={[
            {title:'Pedido', dataIndex:'number'},
            {title:'Embarcador', dataIndex:'name'},
            {title:'Documento matriz', dataIndex:'document' },
            {title:'Documento faturado', dataIndex:'document', render: (_, record) => record.billing_document ? formatDocument(record.billing_document): record.document},
            {title:'Valor do desconto', dataIndex:'value', render:(value)=>`R$ ${value?.toFixed?.(2)}`},
            {title:'Data de cadastro', dataIndex:'createdAt', render:(value)=>value? moment(value).format('DD/MM/YYYY') :'N/A'},
            {title:'Desconto aplicado', dataIndex:'isApplied', render:(value) => value ? 'Sim': 'Não'},
            {title:'Ações', dataIndex:'id', width:'100px',
              render:(_, r) => (
                <Row>
                  <Col flex={'22px'}>
                    <EditFilled
                      style={{fontSize:'18px'}}
                      onClick={()=>{
                        modalForm.setFieldsValue({
                          shipperId: r.shipperId,
                          number: r.number,
                          value: r.value,
                          observation: r.observation,
                        });
                        setEditId(r.id);
                        setEditShipperId(r.shipperId);
                        setModalMode('edit');
                      }}
                      title='Editar'
                    />
                  </Col>
                  <Col flex={'22px'}>
                  <Popconfirm 
                    title='Deseja deletar esse desconto?'
                    onConfirm={()=>
                      deleteDiscount({variables:{id:r.id}})
                        .then(()=>{
                          notification.success({
                            message:'Desconto deletado com sucesso!'
                          })
                          refetch()
                        })
                        .catch(()=>{
                          notification.error({
                            message:'Erro ao deletar desconto!',
                          })
                          refetch()
                        })
                    }
                    >
                    <DeleteFilled
                      style={{fontSize:'18px'}}
                      title='Deletar'
                    />
                    </Popconfirm>
                  </Col>
                  <Col flex={'22px'}>
                    <ZoomInOutlined 
                      style={{fontSize:'18px'}}
                      onClick={()=>{
                        modalForm.setFieldsValue({
                          shipperId: r.shipperId,
                          number: r.number,
                          value: r.value,
                          observation: r.observation,
                        })
                        setEditShipperId(r.shipperId);
                        setModalMode('view');
                      }}
                      title='Vizualizar'
                    />
                  </Col>
                </Row>
              )
            },
          ]}
          dataSource={data?.getAllDiscounts?.discounts ?? []}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            onChange: (current, pageSize) => setPagination({current, pageSize}),
            total: data?.getAllDiscounts?.meta?.totalItems ?? 0,
            current: pagination.current,
          }}
        />

        <Modal 
          title='Desconto'
          confirmLoading={loadingSubmit} 
          onCancel={()=>{
            setModalMode(undefined);
            modalForm.resetFields();
          }}
          onOk={()=>{
            modalForm.validateFields().then(()=>{
              submitOrder(modalMode, modalForm.getFieldsValue());
            })
          }}
          visible={Boolean(modalMode)}
          >
          <Spin spinning={loadingSubmit}>
            <Form form={modalForm} layout='vertical'>
              <Form.Item name='shipperId' label='Embarcador' rules={[{required:true, message:'Campo obrigatório!'}]}>
                <AutoCompleteSearch
                  onSelected={({id})=> modalForm.setFieldsValue({shipperId:id})}
                  query={GET_SHIPPER_NAMES}
                  disabled={modalMode === 'view'}
                  idValue={editShipperId || undefined}
                  showBranches={false}
                />
              </Form.Item>
              <Form.Item hasFeedback name='number' label='Pedido' rules={[{required:true, message:'Campo obrigatório!'}]}>
                <Input 
                  placeholder='Pedido' 
                  disabled={modalMode !== 'new' || loadingOrder}
                  onChange={modalMode === 'view'? undefined: (e)=> setOrderNumber(e.target.value)}
                  type='number'
                />
              </Form.Item>
              <Form.Item name='value' label='Valor do desconto' rules={[{required:true, message:'Campo obrigatório!'}]}>
                <ReactCurrencyInput
                  className="ant-input"
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix="R$ "
                  placeholder='alor do desconto'
                  disabled={modalMode === 'view'}
                />
              </Form.Item>
              <Form.Item name='observation' label='Observação' rules={[{required:true, message:'Campo obrigatório!'}]}>
                <Input.TextArea placeholder='Observação' disabled={modalMode === 'view'}/>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>

      </div>
    </div>
  );
}

export default Discounts;
