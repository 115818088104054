import '../css/error.css';

const Error = (error) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="card">
        <div className="card-body">
          <h5 className="">Erro</h5>
          <p className="card-text">{error.error.message}</p>
        </div>
      </div>
    </div>
  );
};
export default Error;
