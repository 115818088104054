export const objectToFormData = (
    object,
    form,
    namespace
  ) => {
    const fd = form || new FormData();
  
    Object.keys(object).forEach((property) => {
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] || typeof object[property] === 'boolean' || object[property ] === null) {
        // must be truthy
        // if the property is an object, but not a File,
        // use recursivity.
        if (
          typeof object[property] === 'object' &&
          !(object[property] instanceof File) &&
          Object.keys(object[property] ?? {}).length > 0
        ) {
          objectToFormData(object[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, object[property]);
        }
      }
    });
  
    return fd;
  };