import { Descriptions } from 'antd';
import { convertToReal } from '../../common/formatCurrency';
import { baseBucketUrl } from '../../config/client-graphql';

export function BoletoInfo({ boleto }) {
  const createdAt = new Date(boleto.createdAt);
  const due = new Date(boleto.createdAt);
  due.setDate(createdAt.getDate() + 2);

  const convertKnownStatusToPortugues = (status) => {
    switch (status) {
      case 'created':
        return 'Criado';
      case 'registered':
        return 'Registrado';
      case 'failed':
        return 'Falhou';
      case 'canceled':
        return 'Cancelado';
      case 'substituted':
        return 'Substituido';
      case 'deleted':
        return 'Deletado';
      case 'overdue':
        return 'Atrasado';
      case 'paid':
        return 'Pago';
      default:
        return status;
    }
  }

  return (
    <Descriptions
      title="Boleto"
      style={{
        backgroundColor: '#fff',
        padding: '20px',
        marginTop: '20px',
        marginLeft: '0px',
        minWidth: '0',
      }}
      extra={<a href={`${baseBucketUrl}/boletos/${boleto.id}.pdf`}> Baixar Pdf </a>}
      column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
    >
      <Descriptions.Item label="Status">{convertKnownStatusToPortugues(boleto.status)}</Descriptions.Item>
      <Descriptions.Item label="Valor">
        {convertToReal(boleto.amount / 100)}
      </Descriptions.Item>
      <Descriptions.Item label="Data de Criação">
        {createdAt.toLocaleDateString('pt-br')}
      </Descriptions.Item>
      <Descriptions.Item label="Data de vencimento">
        {due.toLocaleDateString('pt-br')}
      </Descriptions.Item>
    </Descriptions>
  );
}
