import { Descriptions } from 'antd';
import { convertToReal } from '../../common/formatCurrency';
import { baseBucketUrl } from '../../config/client-graphql';

export function DebitInfo({ financial }) {
  const createdAt = new Date(financial.createdAt);
  const due = new Date(financial.createdAt);
  due.setDate(createdAt.getDate() + 2);

  return (
    <Descriptions
      title="Nota de débito"
      style={{
        backgroundColor: '#fff',
        padding: '20px',
        marginTop: '20px',
        marginLeft: '0px',
        minWidth: '0',
      }}
      extra={
        <a href={`${baseBucketUrl}/invoices/${financial.debitPdfId}.pdf`}> Baixar Pdf </a>
      }
      column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
    >
      <Descriptions.Item label="Número">{financial.id}</Descriptions.Item>
      <Descriptions.Item label="Valor">
        {convertToReal(financial.debitValue)}
      </Descriptions.Item>
      <Descriptions.Item label="Data de Criação">
        {createdAt.toLocaleDateString('pt-br')}
      </Descriptions.Item>
      <Descriptions.Item label="Data de vencimento">
        {due.toLocaleDateString('pt-br')}
      </Descriptions.Item>
    </Descriptions>
  );
}
