import { useEffect, useMemo, useState } from 'react';
import { Layout } from 'antd';
const { Content } = Layout;
import './pages/css/root.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Sidebar from './components/sidebar/sidebar';
import Topbar from './components/topbar/topbar';
import Billings from './pages/billings';
import Freights from './pages/freights';
import Boletos from './pages/boletos';
import Discounts from './pages/discounts';
import BillDetails from './pages/billDetails';
import ShipperAggregation from './pages/shipperAggregation';
import { SelectedShipperContext } from './context/selectedShipperContext';
import { useLocation } from 'react-router-dom';
import { checkValidation } from './services/logout-service';

const UseTracker =() => {
  const location = useLocation();

  useEffect(() => {
    checkValidation();
  }, [location]);

  return(<></>);
};

function Routes() {

  const [selectedShipper, setSelectedShipper] = useState({
    id: '',
    social_name: '',
  });

  const [selectedBranch, setSelectedBranch] = useState({
    id: '',
    social_name: '',
  });

  const value = useMemo(
    () => ({ selectedShipper: selectedShipper, setSelectedShipper: setSelectedShipper, selectedBranch, setSelectedBranch }),
    [selectedShipper, selectedBranch]
  );

  return (
    <Layout style={{ backgroundColor: '#f0f2f5' }}>
      <Topbar />
      <Layout>
        <BrowserRouter>
          <UseTracker />
          <Sidebar />
          <Layout
            style={{
              backgroundColor: '#f0f2f5',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                minHeight: window.innerHeight - 64,
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Switch>
                <SelectedShipperContext.Provider value={value}>
                  <Route path="/" exact component={Freights} />
                  <Route path="/shippers" component={ShipperAggregation} />
                  <Route path="/billings" exact component={Billings} />
                  <Route path="/billings/:selectedBill" component={BillDetails} />
                  <Route path="/boletos" component={Boletos} />
                  <Route path="/discounts" component={Discounts} />
                </SelectedShipperContext.Provider>

              </Switch>
            </Content>
          </Layout>
        </BrowserRouter>
      </Layout>
    </Layout>
  );
}

export default Routes;
