import { Descriptions } from 'antd';
import { baseBucketUrl } from '../../config/client-graphql';

export function FinancialInfo({ financial }) {
  const createdAt = new Date(financial.createdAt);
  const due = new Date(financial.createdAt);
  due.setDate(createdAt.getDate() + 2);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: '0',
        gap: '1.7%',
      }}
    >
      <Descriptions
        title="Fatura"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          marginTop: '20px',
          marginLeft: '0px',
          minWidth: '0',
        }}
        extra={
          <a href={`${baseBucketUrl}/summaries/${financial.summaryPdfId}.pdf`}>
            {' '}
            Baixar Sumário{' '}
          </a>
        }
        column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Número">{financial.id}</Descriptions.Item>
        <Descriptions.Item label="Status">{financial.status}</Descriptions.Item>
        <Descriptions.Item label="Data de Criação">
          {createdAt.toLocaleDateString('pt-br')}
        </Descriptions.Item>
        <Descriptions.Item label="Data de vencimento">
          {due.toLocaleDateString('pt-br')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
