import { Statistic, Row, Col, Card } from "antd";

const FinancialAggrValues = ({ loading, data, error }) => {
    return (
        <Row gutter={[12, 12]} style={{ marginTop: 16  }}>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                prefix="R$ "
                title="Valor Total"
                value={data?.getFinancialAggrValues?.totalValue.toFixed?.(2)}
                loading={loading}
              />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                prefix="R$ "
                title="Pago"
                value={data?.getFinancialAggrValues?.paid.toFixed?.(2)}
                loading={loading}
              />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic
                prefix="R$ "
                title="Em aberto no prazo"
                value={data?.getFinancialAggrValues?.inDateValue.toFixed?.(2)}
                loading={loading}
              />
            </Card>
          </Col>
          <Col flex={6}>
            <Card style={{ width: '100%', height: '100%' }}>
              <Statistic 
                prefix="R$ "
                title="Em aberto vencido" 
                value={data?.getFinancialAggrValues?.outDateValue.toFixed?.(2)} 
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
    )
};

export default FinancialAggrValues;
