import { ApolloProvider } from '@apollo/client';
import React from 'react';
import Routes from './routes';
import { client } from './config/client-graphql';
import Cookies from 'universal-cookie';
import Login from './pages/login';
import { AuthProvider } from './context/AuthContext/authContext';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale/pt_BR';

function App() {
  const cookies = new Cookies();
  const result = cookies.get(`smart-envios.token-${process.env.REACT_APP_COOKIE_DOMAIN}`);

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <ConfigProvider locale={pt_BR}>
          <div>{result ? <Routes /> : <Login />}</div>
        </ConfigProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}

export default App;
