import React from 'react';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { SelectedShipperContext } from '../../context/selectedShipperContext';
import { formatDocument } from '../../common/formatDocument';

export default function BranchSelect({ onSelected, disabled, selectedShipper}) {
  const [value, setValue] = useState(undefined);
  const [options, setOptions] = useState([]);

  useEffect(()=>{
    setOptions([
      { value: null, label: `Todos` },
      { value: selectedShipper.document, label:`Apenas matriz (${formatDocument(selectedShipper.document)})` },
      {
        value: 'branches',
        label: 'Filiais',
        selectable: false,
        children: (selectedShipper.branches ?? [])?.map(
          ({ document, name })=>({ value: document, label:`${name} (${formatDocument(document)})` })
        )
      },
    ])
  },[selectedShipper]);

  useEffect(() => {
    if(!value) return onSelected({ name: '', document: '' })
    if (selectedShipper) {
      let found = selectedShipper.branches.find((branch)=> branch.document === value)
      
      found ? onSelected(found) : onSelected(selectedShipper);
    }
  }, [value]);

  const onValue = (option) => {
    setValue(option);
  };

  const handleClear = () => {
    onSelected({ id: '', name: '', document: '' });
  };

  return (
    <TreeSelect
      dropdownMatchSelectWidth={400}
      style={{
        width: 300,
        minWidth: '0',
      }}
      treeData={options}
      onClear={handleClear}
      value={value}
      onChange={onValue}
      disabled={options.length === 0 || disabled}
      allowClear
      showSearch
      treeDefaultExpandedKeys={['branches']}
      notFoundContent="Nenhuma filial encontrada"
      placeholder={'Selecione a filial'}
      filterOption={(inputValue, option) =>
        option?.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    ></TreeSelect>
  );
}
