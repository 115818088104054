import Error from '../error/error';
import { Button, Table } from 'antd';
import { useQuery } from '@apollo/client';
import '../css/freightsTable.css';
import { SyncOutlined, DownloadOutlined } from '@ant-design/icons';
import { convertToReal } from '../../common/formatCurrency';
import { BOLETOS_BY_FILTER_PARAMS } from '../../graphql';
import { CustomSpinner } from '../customSpinner/customSpinner';
import { useEffect, useState } from 'react';
import { baseBucketUrl } from '../../config/client-graphql';
import { checkValidation } from '../../services/logout-service';
import saveCsv from '../../services/csv-exporter';
import { formatDocument } from '../../common/formatDocument';

const BoletosTable = ({ shipperName, shipperDocument, billingDocument, dateRange, status }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  let after, before;

  shipperName === '' || null ? (shipperName = null) : null;
  shipperDocument === '' || null ? (shipperDocument = null) : null;

  status === '' || null ? (status = null) : null;

  if (dateRange.length <= 1) {
    after = null;
    before = null;
  } else if (dateRange.length > 1) {
    after = dateRange[0];
    before = dateRange[1];
  }

  const { loading, data, error, refetch } = useQuery(BOLETOS_BY_FILTER_PARAMS, {
    variables: { 
      shipperDocument, 
      billingDocument, 
      status, 
      after, 
      before, 
      selectedPage, 
      pageSize
    },
  });

  useEffect(() => {
    refetch();
  }, [pageSize, selectedPage]);

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomSpinner fontSize={50} />
      </div>
    );

  if (error) return <Error error={error} />;

  const values = data.boletoByFilterParams.boletos;
  const meta = data.boletoByFilterParams.meta;
  const dataRows = [];

  const convertKnownStatusToPortugues = (status) => {
    switch (status) {
      case 'created':
        return 'Criado';
      case 'registered':
        return 'Registrado';
      case 'failed':
        return 'Falhou';
      case 'canceled':
        return 'Cancelado';
      case 'substituted':
        return 'Substituido';
      case 'deleted':
        return 'Deletado';
      case 'overdue':
        return 'Atrasado';
      case 'paid':
        return 'Pago';
      default:
        return status;
    }
  };

  values.map((item) => {
    dataRows.push({
      ...item,
      key: item.id,
      shipper: item.shipper?.name,
      document: item.shipper?.document,
      status: convertKnownStatusToPortugues(item.status),
      createdAt: new Date(item.createdAt),
      amount: item.amount,
      billing: item.financialId,
      pdf: item.id,
    });
  });

  const columns = [
    {
      title: 'Embarcador',
      dataIndex: 'shipper',
      key: 'shipper',
    },
    {
      title: 'Documento matriz',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Documento faturado',
      dataIndex: 'billing_document',
      key: 'billing_document',
      render: (_, record) => record.billing_document ? formatDocument(record.billing_document): record.document,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, _record) => <>{text.toLocaleDateString('pt-br')}</>,
      sorter: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Faturamento',
      dataIndex: 'billing',
      key: 'billing',
      sorter: (a, b) => a.billing - b.billing,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, _record) => <>{convertToReal(text / 100)}</>,
      sorter: (a, b) => a.amount - b.amount,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Pdf',
      dataIndex: 'pdf',
      key: 'pdf',
      render: (text, _record) => (
        <a href={`${baseBucketUrl}/boletos/${text}.pdf`}> Baixar Pdf </a>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="button-group">
        <Button
          icon={<DownloadOutlined />}
          className="download"
          onClick={() => {
            saveCsv(
              'boletos',
              `Embarcador;Status;Criado em;Faturamento;Valor;Pdf`,
              (() => {
                const csvData = [];

                values.map((item) => {
                  let totalValue = 0;
                  if (item.nfses) {
                    for (let nfse of item.nfses) {
                      totalValue += nfse.value;
                    }
                  }

                  const createdAt = new Date(item.createdAt);
                  const due = new Date(item.createdAt);
                  due.setDate(createdAt.getDate() + 2);

                  csvData.push([
                    item.shipper?.name ?? '',
                    convertKnownStatusToPortugues(item.status) ?? '',
                    new Date(item.createdAt).toLocaleDateString('pt-br') ?? '',
                    item.financialId ?? '',
                    item.amount ?? '',
                    `${baseBucketUrl}/boletos/${item.id}.pdf`,
                  ]);
                });

                return csvData;
              })(),
            );
          }}
        >
          Exportar CSV
        </Button>
        <Button
          icon={<SyncOutlined />}
          className="atualizar"
          onClick={() => {
            checkValidation();
            refetch();
          }}
        >
          Atualizar
        </Button>
      </div>

      <Table
        size="middle"
        columns={columns}
        dataSource={dataRows}
        scroll={{ x: 1000 }}
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setSelectedPage(page);
            setPageSize(pageSize);
          },
          total: meta.totalItems,
          current: selectedPage,
        }}
      />
    </div>
  );
};

export default BoletosTable;
