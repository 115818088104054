import { createContext } from 'react';

export const SelectedShipperContext = createContext({
  selectedShipper: {
    id: '',
    name: '',
  },
  setSelectedShipper: () => { },
  setSelectedBranch: () => { },
  selectedBranch: ''
});